import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://api.sgtta.org/api/user/';
//const AUTH_API = 'http://localhost:8080/api/user/';

const apiurl = "https://api.sgtta.org/api/";
//const apiurl = "http://localhost:8080/api/";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  constructor(private http: HttpClient) { }

  adduser(t: { Name: any; UserName: any; Password: any; MobileNo: any; CategoryId: any; EventId: any; IsActive: any; CreatedBy: any; }, username: any, UserImage: any, UserImg: any): Observable<any> {
    return this.http.post(AUTH_API + 'add', {
      Name: t.Name,
      UserName: username,
      Password: t.Password,
      MobileNo: t.MobileNo,
      CategoryId: t.CategoryId,
      EventId: t.EventId,
      IsActive: t.IsActive == true ? "Yes" : "No",
      CreatedBy: t.CreatedBy,
      UserImage: UserImage,
      UserImg: UserImg
    }, httpOptions);
  }

  updateuser(t: { UserId: any; Name: any; Email: any; MobileNo: any; IsActive: any; CreatedBy: any; }, ProfileImage: any, ProfileImg: any): Observable<any> {
    return this.http.post(AUTH_API + 'adminupdate', {
      UserId: t.UserId,
      Name: t.Name,
      Email: t.Email,
      MobileNo: t.MobileNo,
      IsActive: t.IsActive == true ? "Yes" : "No",
      CreatedBy: t.CreatedBy,
      ProfileImage: ProfileImage,
      ProfileImg: ProfileImg
    }, httpOptions);
  }

  getuser(): Observable<any> {
    return this.http.get(AUTH_API + 'get', httpOptions);
  }

  finduserById(UserId: number): Observable<any> {
    return this.http.get(AUTH_API + 'findById?UserId=' + UserId, httpOptions);
  }

  deleteuser(UserId: number): Observable<any> {
    return this.http.get(AUTH_API + 'delete?UserId=' + UserId, httpOptions);
  }

  changepassworduser(t: { UserId: any; Password: any; }): Observable<any> {
    return this.http.post(AUTH_API + 'changepassword', {
      UserId: t.UserId,
      Password: t.Password
    }, httpOptions);
  }

  GetUserbyEventId(EventId: number): Observable<any> {
    return this.http.get(AUTH_API + 'GetUserbyEventId?EventId=' + EventId, httpOptions);
  }

  getstatelist(): Observable<any> {
    return this.http.get(apiurl + "auth/GetStateList", httpOptions);
  }

  getcitylist(StateName: any): Observable<any> {
    return this.http.get(apiurl + "auth/GetCityList?StateName=" + StateName, httpOptions);
  }

  addCustomer(t: { UserId: any; MobileNo: any; State: any; City: any; CategoryId: any; SubCategoryId: any; IsGST: any; GSTIN: any; PanCard: any; LegalName: any; FirmName: any; RegisteredAddress: any; GSTRegDate: any; BrandName: any; BusinessStartDate: any; Address: any; ContactPerson: any; CreatedBy: any; RefBy: any; IsActive: any; IsVerify: any; }, BusinessImage: any, BusinessImg: any, Branchlist: any): Observable<any> {
    return this.http.post(apiurl + "business/addCustomer", {
      MobileNo: t.MobileNo,
      State: t.State,
      City: t.City,
      CategoryId: t.CategoryId,
      SubCategoryId: t.SubCategoryId != '' && t.SubCategoryId != undefined && t.SubCategoryId != null ? t.SubCategoryId : 0,
      IsGST: t.IsGST,
      GSTIN: t.GSTIN,
      PanCard: t.PanCard,
      LegalName: t.LegalName,
      FirmName: t.FirmName,
      RegisteredAddress: t.RegisteredAddress,
      GSTRegDate: t.GSTRegDate,
      BrandName: t.BrandName,
      BusinessStartDate: t.BusinessStartDate,
      Address: t.Address,
      ContactPerson: t.ContactPerson,
      CreatedBy: t.CreatedBy,
      BusinessImage: BusinessImage,
      BusinessImg: BusinessImg,
      Branchlist: Branchlist,
      RefBy: t.RefBy,
      UserId: t.UserId,
      IsActive: t.IsActive,
      IsVerify: t.IsVerify
    }, httpOptions);
  }

  addBusinessAdmin(t: { UserId: any; RegisteredMobileNo: any; MobileNo: any; State: any; City: any; CategoryId: any; SubCategoryId: any; IsGST: any; GSTIN: any; PanCard: any; LegalName: any; FirmName: any; RegisteredAddress: any; GSTRegDate: any; BrandName: any; BusinessStartDate: any; Address: any; ContactPerson: any; CreatedBy: any; RefBy: any; IsActive: any; IsVerify: any; }, BusinessImage: any, BusinessImg: any, Branchlist: any): Observable<any> {
    return this.http.post(apiurl + "business/addBusinessAdmin", {
      RegisteredMobileNo:t.RegisteredMobileNo,
      MobileNo: t.MobileNo,
      State: t.State,
      City: t.City,
      CategoryId: t.CategoryId,
      SubCategoryId: t.SubCategoryId != '' && t.SubCategoryId != undefined && t.SubCategoryId != null ? t.SubCategoryId : 0,
      IsGST: t.IsGST,
      GSTIN: t.GSTIN,
      PanCard: t.PanCard,
      LegalName: t.LegalName,
      FirmName: t.FirmName,
      RegisteredAddress: t.RegisteredAddress,
      GSTRegDate: t.GSTRegDate,
      BrandName: t.BrandName,
      BusinessStartDate: t.BusinessStartDate,
      Address: t.Address,
      ContactPerson: t.ContactPerson,
      CreatedBy: t.CreatedBy,
      BusinessImage: BusinessImage,
      BusinessImg: BusinessImg,
      Branchlist: Branchlist,
      RefBy: t.RefBy,
      UserId: t.UserId,
      IsActive: t.IsActive,
      IsVerify: t.IsVerify
    }, httpOptions);
  }

  UpdateCustomer(t: { BusinessId: any; RegisteredMobileNo:any; UserId: any; MobileNo: any; State: any; City: any; CategoryId: any; SubCategoryId: any; IsGST: any; GSTIN: any; PanCard: any; LegalName: any; FirmName: any; RegisteredAddress: any; GSTRegDate: any; BrandName: any; BusinessStartDate: any; Address: any; ContactPerson: any; CreatedBy: any; RefBy: any; IsActive :any; IsVerify: any; }, BusinessImage: any, BusinessImg: any, Branchlist: any, DBranchlist: any): Observable<any> {
    return this.http.post(apiurl + "business/UpdateCustomer", {
      RegisteredMobileNo:t.RegisteredMobileNo,
      MobileNo: t.MobileNo,
      State: t.State,
      City: t.City,
      CategoryId: t.CategoryId,
      SubCategoryId: t.SubCategoryId != '' && t.SubCategoryId != undefined && t.SubCategoryId != null ? t.SubCategoryId : 0,
      IsGST: t.IsGST,
      GSTIN: t.GSTIN,
      PanCard: t.PanCard,
      LegalName: t.LegalName,
      FirmName: t.FirmName,
      RegisteredAddress: t.RegisteredAddress,
      GSTRegDate: t.GSTRegDate,
      BrandName: t.BrandName,
      BusinessStartDate: t.BusinessStartDate,
      Address: t.Address,
      ContactPerson: t.ContactPerson,
      CreatedBy: t.CreatedBy,
      BusinessImage: BusinessImage,
      BusinessImg: BusinessImg,
      Branchlist: Branchlist,
      DBranchlist: DBranchlist,
      RefBy: t.RefBy,
      UserId: t.UserId,
      BusinessId: t.BusinessId,
      IsActive : t.IsActive,
      IsVerify : t.IsVerify
    }, httpOptions);
  }

  GetBusinessListbyBusinessId(BusinessId: any): Observable<any> {
    return this.http.get(apiurl + "business/GetBusinessListbyBusinessId?BusinessId=" + BusinessId, httpOptions);
  }

  getproductlist(): Observable<any> {
    return this.http.get(apiurl + "subcategory/GetProductList", httpOptions);
  }

  GetBusinessList(): Observable<any> {
    return this.http.get(apiurl + "business/GetBusinessList", httpOptions);
  }

  GetPendingVerificationBusinessList(type: any): Observable<any> {
    return this.http.get(apiurl + "business/GetPendingVerificationBusinessList?type=" + type, httpOptions);
  }

  GetProductListAdmin(): Observable<any> {
    return this.http.get(apiurl + "subcategory/GetProductListAdmin", httpOptions);
  }

  GetBranchListbyBusinessId(BusinessId: any): Observable<any> {
    return this.http.get(apiurl + "business/GetBranchListbyBusinessId?BusinessId=" + BusinessId, httpOptions);
  }

  GetProductListbyBusinessId(BusinessId: any): Observable<any> {
    return this.http.get(apiurl + "business/GetProductListbyBusinessId?BusinessId=" + BusinessId, httpOptions);
  }

  GetSupport(): Observable<any> {
    return this.http.get(apiurl + "support/get", httpOptions);
  }

  AddSupport(t: { Email: any; Map: any; MobileNo: any; Address:any; WhatsappNo: any;Facebook:any;Instagram: any;Twitter: any;Linkedin:any;Youtube:any;}): Observable<any> {
    return this.http.post(apiurl + "support/add", {
      MobileNo: t.MobileNo,
      Email: t.Email,
      Map: t.Map,
      WhatsappNo: t.WhatsappNo,
      Instagram: t.Instagram,
      Twitter: t.Twitter,
      LinkedIn: t.Linkedin,
      Youtube: t.Youtube,
      Address: t.Address,
      Facebook: t.Facebook
    }, httpOptions);
  }

  addAbout(t: { Aboutus: any; Terms: any; Policy: any;AId:any;}): Observable<any> {
    return this.http.post(apiurl + 'about/add', {
      Aboutus: t.Aboutus,
      Terms: t.Terms,
      Policy: t.Policy,
      AId:t.AId
    }, httpOptions);
  }

  addAboutBanner(BannerImage:any,BannerImg:any): Observable<any> {
    return this.http.post(apiurl + 'about/addAboutBanner', {
      BannerImage: BannerImage,
      BannerImg: BannerImg
    }, httpOptions);
  }

  addAboutMember(t: { AboutMember: any; MemberName: any;},MemberImage:any,MemberImg:any): Observable<any> {
    return this.http.post(apiurl + 'about/addAboutMember', {
      AboutMember: t.AboutMember,
      MemberImage: MemberImage,
      MemberImg: MemberImg,
      MemberName: t.MemberName 
    }, httpOptions);
  }

  updateAboutMember(t: {AMId:any; AboutMember: any; MemberName: any;},MemberImage:any,MemberImg:any): Observable<any> {
    return this.http.post(apiurl + 'about/updateAboutMember', {
      AMId: t.AMId,
      AboutMember: t.AboutMember,
      MemberImage: MemberImage,
      MemberImg: MemberImg,
      MemberName: t.MemberName 
    }, httpOptions);
  }

  getabout(): Observable<any> {
    return this.http.get(apiurl + 'about/get', httpOptions);
  }

  getaboutbanner(): Observable<any> {
    return this.http.get(apiurl + 'about/getaboutbanner', httpOptions);
  }

  getaboutmember(): Observable<any> {
    return this.http.get(apiurl + 'about/getaboutmember', httpOptions);
  }

  findAMById(AMId : number): Observable<any> {
    return this.http.get(apiurl + 'about/findAMById?AMId='+ AMId, httpOptions);
  }

  deleteAboutBanner(ABId : number): Observable<any> {
    return this.http.get(apiurl + 'about/deleteAboutBanner?ABId='+ ABId, httpOptions);
  }

  deleteAboutMember(AMId : number): Observable<any> {
    return this.http.get(apiurl + 'about/deleteAboutMember?AMId='+ AMId, httpOptions);
  }

  addBanner(BannerImage:any,BannerImg:any): Observable<any> {
    return this.http.post(apiurl + 'about/addBanner', {
      BannerImage: BannerImage,
      BannerImg: BannerImg
    }, httpOptions);
  }

  deleteBanner(BNId : number): Observable<any> {
    return this.http.get(apiurl + 'about/deleteBanner?BNId='+ BNId, httpOptions);
  }

  getbanner(): Observable<any> {
    return this.http.get(apiurl + 'about/getbanner', httpOptions);
  }

  verifyGSTIN(gstin: any): Observable<any> {
    return this.http.get(apiurl + "business/verifyGSTIN?gstin=" + gstin, httpOptions);
  } 

  addfaq(t: { Question: any; Answer: any;}): Observable<any> {
    return this.http.post(apiurl + 'faq/addfaq', {
      Question: t.Question,
      Answer: t.Answer
    }, httpOptions);
  }

  updatefaq(t: {FaqId:any;  Question: any; Answer: any; }): Observable<any> {
    return this.http.post(apiurl + 'faq/updatefaq', {
      FaqId: t.FaqId,
      Question: t.Question,
      Answer: t.Answer
    }, httpOptions);
  }

  getfaq(): Observable<any> {
    return this.http.get(apiurl + 'faq/getfaq', httpOptions);
  }

  findfaqById(FaqId : number): Observable<any> {
    return this.http.get(apiurl + 'faq/findfaqById?FaqId='+ FaqId, httpOptions);
  }

  deletefaq(FaqId : number): Observable<any> {
    return this.http.get(apiurl + 'faq/deletefaq?FaqId='+ FaqId, httpOptions);
  }

  adddispute(t: { DisputeNo: any; FirstBusinessId: any;SecondBusinessId: any; DisputeDate :any;Status:any;}): Observable<any> {
    return this.http.post(apiurl + 'dispute/adddispute', {
      DisputeNo: t.DisputeNo,
      FirstBusinessId: t.FirstBusinessId,
      SecondBusinessId : t.SecondBusinessId,
      DisputeDate: t.DisputeDate,
      Status: t.Status
    }, httpOptions);
  }

  updatedispute(t: {DId:any;  DisputeNo: any; FirstBusinessId: any;SecondBusinessId: any; DisputeDate :any;Status:any;}): Observable<any> {
    return this.http.post(apiurl + 'dispute/updatedispute', {
      DId: t.DId,
      DisputeNo: t.DisputeNo,
      FirstBusinessId: t.FirstBusinessId,
      SecondBusinessId : t.SecondBusinessId,
      DisputeDate: t.DisputeDate,
      Status: t.Status
    }, httpOptions);
  }

  getdispute(): Observable<any> {
    return this.http.get(apiurl + 'dispute/getdispute', httpOptions);
  }

  finddisputeById(DId : number): Observable<any> {
    return this.http.get(apiurl + 'dispute/finddisputeById?DId='+ DId, httpOptions);
  }

  deletedispute(DId : number): Observable<any> {
    return this.http.get(apiurl + 'dispute/deletedispute?DId='+ DId, httpOptions);
  }
  
  GetBusinessListforDispute(): Observable<any> {
    return this.http.get(apiurl + 'business/GetBusinessListforDispute', httpOptions);
  }

}
