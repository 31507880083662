<div class="container-fluid">
    <app-pagetitle title="About" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="validationform">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Aboutus</label>
                                    <input type="text" class="form-control" formControlName="Aboutus" placeholder="Aboutus" [ngClass]="{'is-invalid': submit && form.Aboutus.errors}">
                                    <div *ngIf="submit && form.Aboutus.errors" class="invalid-feedback">
                                        <span *ngIf="form.Aboutus.errors.required">Please provide
                                            Aboutus</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Terms & Condition</label>
                                    <input type="text" class="form-control" formControlName="Terms" placeholder="Terms" [ngClass]="{'is-invalid': submit && form.Terms.errors}">
                                    <div *ngIf="submit && form.Terms.errors" class="invalid-feedback">
                                        <span *ngIf="form.Terms.errors.required">Please provide
                                            Terms & Conditions</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Policy</label>
                                    <textarea class="form-control" formControlName="Policy" placeholder="Policy" [ngClass]="{'is-invalid': submit && form.Policy.errors}"></textarea>
                                    <div *ngIf="submit && form.Policy.errors" class="invalid-feedback">
                                        <span *ngIf="form.Policy.errors.required">Please provide
                                            Policy</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                    <button class="btn btn-primary" type="button" (click)="onSubmit()" [disabled]='validationform.invalid'>Save</button>
                </div>
            </div>
        </div>
    </div>
</div>