import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { GuestService } from '../../core/services/guest.service';
import { UserProfileService } from '../../core/services/user.service';
import { TokenStorageService } from '../../core/services/token-service.service';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';
type AOA = any[][];
@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('myInput') myInputVariable: any;
  @ViewChild('myImg') myImgVariable: any;
  validationform: FormGroup;
  data;
  tabindex = 2;
  flag: number = 0;
  addflag: any = '';
  viewflag: any = 'active';
  userflag: any;
  logoimg: any;
  logoimgsrc: any;
  currentFilter: any;
  applyFilterTypes: any;
  datalist = [];
  constructor(public formBuilder: FormBuilder,
    private userService: UserProfileService,
    private tokenStorage: TokenStorageService,
    private businessService: GuestService,
    private sanitizer :DomSanitizer) { }

  breadCrumbItems: Array<{}>;
  submit: boolean;
  get form() {
    return this.validationform.controls;
  }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'SGTTA-SURAT' }, { label: 'Profile', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.validationform = this.formBuilder.group({
      Name: ['', [Validators.required]],
      Email: ['', [Validators.required]],
      MobileNo: ['', [Validators.required, Validators.pattern("^[6-9][0-9]{9}$")]],
      ProfileImage: '',
      IsActive: [true],
      CreatedBy: [this.userflag.id],
      UserId: 0
    });
    this.getdata();
  }

  getdata() {

    this.userService.getuser().subscribe(
      (data: any) => {
        this.datalist = data;
      },
      (err: any) => {

      }
    );
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.logoimg = this.generate() + '_' + file.name;
      reader.readAsDataURL(file);
      var img = new Image();
      reader.onload = () => {
        img.src = reader.result as string;
      };

      img.onload = () => {
        this.logoimgsrc = this.imageToDataUri(img, 500, 500);
      }

    }
  }

  imageToDataUri(img, width, height) {

    // create an off-screen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
  }

  generate() {
    const characters = 'abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result +=
        characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  onSubmit(): void {
    this.submit = true;
   
      this.userService.updateuser(this.validationform.value, this.logoimg, this.logoimgsrc).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.logoimgsrc = "";
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
  
  }


  edituserbyid(e) {
    //console.log(id);
    this.userService.finduserById(e.row.data.UserId).subscribe(
      (data: any) => {
        //console.log(this.userflag);
        var f = data[0];
        this.validationform.setValue({
          Name: f.Name,
          Email: f.Email,
          MobileNo:f.MobileNo,
          ProfileImage: '',
          IsActive: f.IsActive == 'Yes' ? true : false,
          CreatedBy: this.userflag.id,
          UserId: f.UserId
        });
        this.logoimg = f.ProfileImage;
        this.logoimgsrc = f.ProfileImg == '' ? '' : this.sanitizer.bypassSecurityTrustUrl('http://api.sgtta.org/uploads/profile/' + f.ProfileImage);
        this.flag = 1;
        this.tabindex = 1;
        //this.nav.select(1);
      },
      (err: any) => {

      }
    );
  }



  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ProfileList.xlsx');
      });
    });
    e.cancel = true;
  }

  onFileUpChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      //console.log(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      /* save data */
      this.data = XLSX.utils.sheet_to_json(ws, { header: 1,raw: false }); 
      console.log(this.data);
      this.adddata(this.data);
    };
    reader.readAsBinaryString(target.files[0]);

    // if(this.data.length > 0){

    // }
  }

  adddata(lst: any) {
    for (let i = 1; i < lst.length; i++) {
      console.log(lst[i]);
      var t = {
        MobileNo : lst[i][0],
        State:lst[i][1],
        City:lst[i][2],
        CategoryId:lst[i][3],
        SubCategoryId:lst[i][4],
        IsGST:lst[i][5],
        GSTIN: (lst[i][6] != undefined && lst[i][6] != null && lst[i][6] != '' ? lst[i][6]  : '') ,
        PanCard:lst[i][7],
        LegalName:lst[i][8],
        FirmName:lst[i][9],
        RegisteredAddress:lst[i][10],
        GSTRegDate:lst[i][11],
        BrandName:lst[i][12],
        BusinessStartDate:lst[i][13],
        Address:lst[i][14],
        ContactPerson:lst[i][15],
        CreatedBy: this.userflag.id
      }
      this.businessService.addbusiness(t,'',null).subscribe(
        (data: any) => {
          if(i == lst.length-1){
            alert(data.message);
            this.myInputVariable.nativeElement.value = "";
            this.getdata();
          }
        },
        (err: any) => {

        }
      );
    }
  }

}
