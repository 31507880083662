import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://api.sgtta.org/api/log/';
//const AUTH_API = 'http://localhost:8080/api/log/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private http: HttpClient) { }

  addlog(t: { CategoryId: any; UserId: any; GuestId: any; EventId:any;}): Observable<any> {
    return this.http.post(AUTH_API + 'add', {
      CategoryId: t.CategoryId,
      EventId: t.EventId,
      UserId: t.UserId,
      GuestId:t.GuestId
    }, httpOptions);
  }

  getlogbyUserId(UserId : number): Observable<any> {
    return this.http.get(AUTH_API + 'getlogbyUserId?UserId='+ UserId, httpOptions);
  }

  getlogbyEventId(EventId : number): Observable<any> {
    return this.http.get(AUTH_API + 'getlogbyEventId?EventId='+ EventId, httpOptions);
  }

  getlogbyGuestId(GuestId : number): Observable<any> {
    return this.http.get(AUTH_API + 'getlogbyGuestId?GuestId='+ GuestId, httpOptions);
  }

  getlastlog(EventId:any): Observable<any> {
    return this.http.get(AUTH_API + 'getlastlog?EventId='+ EventId, httpOptions);
  }

  getdashboard(EventId:any): Observable<any> {
    return this.http.get(AUTH_API + 'getdashboard?EventId='+ EventId, httpOptions); 
  }

  getloglist(EventId:any): Observable<any> {
    return this.http.get(AUTH_API + 'getloglist?EventId='+ EventId, httpOptions); 
  }
  
  getloglistCheckin(EventId:any,CategoryId:any): Observable<any> {
    return this.http.get(AUTH_API + 'getloglistCheckin?EventId='+ EventId + "&CategoryId=" + CategoryId, httpOptions); 
  }
  
}
