<div class="container-fluid">
    <app-pagetitle title="Product" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="tabindex" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-pen-square"></i></span>
                                <span class="d-none d-sm-block">Add Category for Rating</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body"> -->
                    <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="validationform">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="validationCustom02">Category</label>
                                    <select class="form-control" id="validationCustom03" formControlName="CategoryId" (change)="getcatlist()" [ngClass]="{'is-invalid': submit && form.CategoryId.errors}">
                                                            <option *ngFor="let cat of catlist" [value]="cat.CategoryId">{{cat.CategoryName}}</option>
                                                          </select>
                                    <div *ngIf="submit && form.CategoryId.errors" class="invalid-feedback">
                                        <span *ngIf="form.CategoryId.errors.required">Please select a
                                                                Category.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="validationCustom23">Allow Category for Rating</label>
                                    <dx-list [dataSource]="tasks" [height]="600" [selectionMode]="selectionModeValue" [selectAllMode]="selectAllModeVlaue" [showSelectionControls]="true" [selectedItemKeys]="[]" [(selectedItems)]="rcatlist"></dx-list>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-primary" type="submit" [disabled]='validationform.invalid'>Save</button>
                    </form>
                </div>
            </div>
            <!-- </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="CategoryId" [showBorders]="true">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column dataField="SubcategoryName" caption="Product" [width]="200" dataType="string">
                                                    
            </dxi-column>
            <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string">

            </dxi-column>

            <dxi-column dataField="IsActive" caption="Is Active" [width]="100" dataType="string">
               
            </dxi-column>

            <dxo-pager>
                [showNavigationButtons]="true" [showInfo]= "true"
            </dxo-pager>
            <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
            </dx-data-grid>
        </div>
    </div>
</div>
</ng-template>
</li>
</ul>
<div [ngbNavOutlet]="nav"></div>
</div>
</div> -->
        </div>
    </div>
</div>