<div class="container-fluid">
    <app-pagetitle title="Event" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="tabindex" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-pen-square"></i></span>
                                <span class="d-none d-sm-block">Add New Event</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="validationform">


                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Event Name </label>
                                                        <input type="text" class="form-control" formControlName="EventName" placeholder="Event Name" [ngClass]="{'is-invalid': submit && form.EventName.errors}">
                                                        <div *ngIf="submit && form.EventName.errors" class="invalid-feedback">
                                                            <span *ngIf="form.EventName.errors.required">Please provide
                                                                a
                                                                Event Name</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="validationCustom04">Date of Event</label>
                                                        <input type="date" class="form-control" id="validationCustom04" formControlName="EventDate" placeholder="DateofEvent" [ngClass]="{'is-invalid': submit && form.EventDate.errors}">
                                                        <div *ngIf="submit && form.EventDate.errors" class="invalid-feedback">
                                                            <span *ngIf="form.EventDate.errors.required">Please
                                                                provide a
                                                                Date of Event.</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="validationCustom05">Description</label>
                                                        <textarea class="form-control" id="validationCustom05" formControlName="Description" placeholder="Description" [ngClass]="{'is-invalid': submit && form.Description.errors}"></textarea>
                                                        <div *ngIf="submit && form.Description.errors" class="invalid-feedback">
                                                            <span *ngIf="form.Description.errors.required">Please provide a
                                                                valid
                                                                Description.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="validationCustom23">Event Image</label>
                                                        <input #myInput type="file" class="form-control" id="validationCustom23" formControlName="EventImage" [ngClass]="{'is-invalid': submit && form.EventImage.errors}" (change)="onFileChange($event)" accept="image/png,image/jpg, image/jpeg">
                                                        <div *ngIf="submit && form.EventImage.errors" class="invalid-feedback">
                                                            <span *ngIf="form.EventImage.errors.required">Please provide a
                                                                valid
                                                                Event Image.</span>
                                                        </div>
                                                        <img [src]="logoimgsrc" style="width:100px;" />
                                                    </div>
                                                </div>


                                            </div>



                                            <button class="btn btn-primary" type="submit" [disabled]="validationform.invalid">Save</button>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">Event List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="EventId" [showBorders]="true">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column dataField="EventName" caption="Event Name" dataType="string">

                                                </dxi-column>
                                                <dxi-column dataField="EventDate" caption="MobileNo" dataType="string"></dxi-column>
                                                <dxi-column dataField="Description" caption="Description" dataType="string"></dxi-column>
                                                <dxi-column dataField="EventImg" caption="Event Image" [width]="150" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate">
                                                </dxi-column>

                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                                <div *dxTemplate="let data of 'cellTemplate'">
                                                    <img [src]="data.value" style="width:100px;" />
                                                </div>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
    </div>


</div>