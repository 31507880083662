import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ValidatorFn } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserProfileService } from '../../core/services/user.service';
import { TokenStorageService } from '../../core/services/token-service.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  validationform : FormGroup;
  applyFilterTypes;
  currentFilter;
  userflag;
  aid =0;
  constructor(public formBuilder: FormBuilder,
    private userService: UserProfileService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private tokenStorage: TokenStorageService) {
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];
    this.currentFilter = this.applyFilterTypes[0].key;

    this.validationform = this.formBuilder.group({
      Aboutus: [""],
      Terms: [""],
      Policy: [""],
      AId:[0]
    });

  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  submit: boolean;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'SGTTA-SURAT' }, { label: 'About', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.submit = false;
this.getdata();
  }

  get form() {
    return this.validationform.controls;
  }
  
  getdata() {
    this.userService.getabout().subscribe(
      (data: any) => {
        var t = data[0];
        this.validationform.controls['Aboutus'].setValue(t.Aboutus);
        this.validationform.controls['Terms'].setValue(t.Terms);
        this.validationform.controls['Policy'].setValue(t.Policy);
        this.validationform.controls['AId'].setValue(t.AId);
        this.aid = t.AId;
      },
      (err: any) => {

      }
    );
  }

  onSubmit(): void {
    this.submit = true;
    var t = {
      Aboutus: this.validationform.controls['Aboutus'].value,
      Terms: this.validationform.controls['Terms'].value,
      Policy: this.validationform.controls['Policy'].value,
      AId: this.aid
    }
    console.log(t);
      this.userService.addAbout(t).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
        },
        (err: any) => {

        }
      );
  
  }


}
