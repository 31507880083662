<div class="container-fluid">
    <app-pagetitle title="Dispute" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="tabindex" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-pen-square"></i></span>
                                <span class="d-none d-sm-block">Add Dispute</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="validationform">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Dispute No</label>
                                                        <input type="text" class="form-control" id="validationCustom01" formControlName="DisputeNo" placeholder="DisputeNo" [ngClass]="{'is-invalid': submit && form.DisputeNo.errors}">
                                                        <div *ngIf="submit && form.DisputeNo.errors" class="invalid-feedback">
                                                            <span *ngIf="form.DisputeNo.errors.required">Please provide
                                                                a
                                                                DisputeNo</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">First Party</label>
                                                        <dx-select-box placeholder="Choose First Party" formControlName="FirstBusinessId" [dataSource]="catlist" displayExpr="BrandName" valueExpr="BusinessId" [searchMode]="searchModeOption" [searchExpr]="CatsearchExprOption" [searchTimeout]="searchTimeoutOption"
                                                            [minSearchLength]="minSearchLengthOption" [searchEnabled]="true" [showDataBeforeSearch]="showDataBeforeSearchOption" style="width: 100%; margin-left: 10px;"></dx-select-box>
                                                        <div *ngIf="submit && form.FirstBusinessId.errors" class="invalid-feedback">
                                                            <span *ngIf="form.FirstBusinessId.errors.required">Please select a
                                                                First Party.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Second Party</label>
                                                        <dx-select-box placeholder="Choose Second Party" formControlName="SecondBusinessId" [dataSource]="catlist" displayExpr="BrandName" valueExpr="BusinessId" [searchMode]="searchModeOption" [searchExpr]="CatsearchExprOption" [searchTimeout]="searchTimeoutOption"
                                                            [minSearchLength]="minSearchLengthOption" [searchEnabled]="true" [showDataBeforeSearch]="showDataBeforeSearchOption" style="width: 100%; margin-left: 10px;"></dx-select-box>
                                                        <div *ngIf="submit && form.SecondBusinessId.errors" class="invalid-feedback">
                                                            <span *ngIf="form.SecondBusinessId.errors.required">Please select a
                                                            Second Party.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="validationCustom01"> Dispute Date</label>
                                                        <input type="date" class="form-control" formControlName="DisputeDate" placeholder="GST Registered Date" [ngClass]="{'is-invalid': submit && form.DisputeDate.errors}">
                                                        <div *ngIf="submit && form.DisputeDate.errors" class="invalid-feedback">
                                                            <span *ngIf="form.DisputeDate.errors.required">Please provide
                                                                
                                                               Dispute Date</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>
                                                            <input type="radio" value="Running" formControlName="Status">
                                                            <span>Running</span>
                                                            </label>
                                                        <label>
                                                            <input type="radio" value="Solved" formControlName="Status">
                                                            <span>Solved</span>
                                                            </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <button class="btn btn-primary" type="submit" [disabled]='validationform.invalid'>Save</button>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">Dispute List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="DId" [showBorders]="true">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column dataField="DisputeNo" caption="Dispute No" [width]="200" dataType="string">

                                                </dxi-column>
                                                <dxi-column dataField="FirstBusinessName" caption="First Party" [width]="200" dataType="string">

                                                </dxi-column>
                                                <dxi-column dataField="SecondBusinessName" caption="Second Party" [width]="200" dataType="string">

                                                </dxi-column>
                                                <dxi-column dataField="DisputeDate" caption="Dispute Date" [width]="100" dataType="string">

                                                </dxi-column>

                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>

                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
    </div>
</div>