import { Component, OnInit,ViewChild} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { GuestService } from '../../core/services/guest.service';
import { BackstageService } from '../../core/services/backstage.service';
import { TokenStorageService } from '../../core/services/token-service.service';
import { EventservService } from '../../core/services/eventserv.service';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';

@Component({
  selector: 'app-backstage',
  templateUrl: './backstage.component.html',
  styleUrls: ['./backstage.component.scss']
})
export class BackstageComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  currentFilter: any;
  applyFilterTypes:any;
  validationform: FormGroup;
  tabindex = 1;
  flag: number = 0;
  addflag: any = '';
  viewflag: any = 'active';
  userflag: any;
  datalist = [];
  guestlist = [];
  eventlist = [];
  presenterlist = [];
  logoimgsrc: any;
  logoimg: any;

  constructor(public formBuilder: FormBuilder,
    private guestService: GuestService,
    private backstageService: BackstageService,
    private eventService: EventservService,
    private router: Router,
    private tokenStorage: TokenStorageService) { 
      this.applyFilterTypes = [{
        key: 'auto',
        name: 'Immediately',
      }, {
        key: 'onClick',
        name: 'On Button Click',
      }];
      this.currentFilter = this.applyFilterTypes[0].key;

    }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  submit: boolean;
  dtOptions: any = {};
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'EVENT' }, { label: 'Guest', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.validationform = this.formBuilder.group({
      GuestId: ['', [Validators.required]],
      PresenterId: ['', [Validators.required]],
      EventId: [this.userflag.EventId],
      CreatedBy: [this.userflag.id],
      ModifyBy: [this.userflag.id],
      BcId: 0
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
      buttons: [
        'copy', 'csv', 'excel', 'print'
      ]
    };

    this.submit = false;
    this.getdata();

  }

  get form() {
    return this.validationform.controls;
  }

  cleartext() {
    this.validationform = this.formBuilder.group({
      GuestId: ['', [Validators.required]],
      PresenterId: ['', [Validators.required]],
      EventId: [this.userflag.EventId],
      CreatedBy: [this.userflag.id],
      ModifyBy: [this.userflag.id],
      BcId: 0
    });
    this.flag = 0;
    this.submit = false;
  }

  getdata() {
    this.backstageService.GetBackstagebyEventId(this.userflag.EventId).subscribe(
      (data: any) => {
        this.datalist = data;
      },
      (err: any) => {

      }
    );

    this.guestService.GetGuestListbyEventId(this.userflag.EventId).subscribe(
      (data: any) => {
        this.guestlist = data;
        this.presenterlist = data;
      },
      (err: any) => {

      }
    );

   

    // this.eventService.getevent().subscribe(
    //   (data: any) => {
    //     this.eventlist = data;
    //   },
    //   (err: any) => {

    //   }
    // );
  }


  onSubmit(): void {
    this.submit = true;
    if (this.flag == 0) {

      this.backstageService.addbackstage(this.validationform.value).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
    else {
      this.backstageService.updatebackstage(this.validationform.value).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
  }


  edituserbyid(e) {
    //console.log(id);
    this.backstageService.findbackstageById(e.row.data.BcId).subscribe(
      (data: any) => {
        //console.log(this.userflag);
        var f = data;
        console.log(data);
        this.validationform.setValue({
          EventId: f.EventId,
          GuestId: f.GuestId,
          PresenterId: f.PresenterId,
          CreatedBy: this.userflag.id,
          ModifyBy: this.userflag.id,
          BcId: f.BcId
        });
        this.guestService.GetGuestListbyEventId(f.EventId).subscribe(
          (data: any) => {
            this.guestlist = data;
            this.validationform.controls["GuestId"].setValue(f.GuestId);
            this.presenterlist = data;
            this.validationform.controls["PresenterId"].setValue(f.GuestId);
          },
          (err: any) => {
    
          }
        );       
        this.flag = 1;
        this.tabindex = 1;
        //this.nav.select(1);
      },
      (err: any) => {

      }
    );
  }

  deleteuser(e) {
    this.backstageService.deletebackstage(e.row.data.BcId).subscribe(
      (data: any) => {
        alert(data.message);
        this.getdata();
      },
      (err: any) => {

      }
    );
  }

  updatestatus(e) {
    if(e.row.data.IsComplete == "No"){
      var t = {BcId: e.row.data.BcId , ModifyBy: this.userflag.id };
      this.backstageService.updatestatus(t).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
        },
        (err: any) => {

        }
      );
    }
    else{
      alert("Stage Entry already Complete");
    }
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'BackstageList.xlsx');
      });
    });
    e.cancel = true;
  }

}
