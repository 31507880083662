import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-service.service';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private authFackservice: TokenStorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // if (environment.defaultauth === 'firebase') {
        //     const currentUser = this.authenticationService.currentUser();
        //     if (currentUser) {
        //         // logged in so return true
        //         return true;
        //     }
        // } else {
            const currentUser = this.authFackservice.getUser();
            console.log(currentUser);
            if (currentUser != null && currentUser != undefined && currentUser != '') {
                // logged in so return true
                console.log('in');
                return true;
            }
        // }
        // not logged in so redirect to login page with the return url  , { queryParams: { returnUrl: state.url } }
        this.router.navigate(['/account/login']);
        return false;
    }
}
