import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://api.sgtta.org/api/category/';
// const AUTH_API = 'https://sgttaapi.airconcollabs.com/api/category/';
//const AUTH_API = 'http://localhost:8080/api/category/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  addcategory(t: { CategoryName: any; IsActive: any; CreatedBy: any;},CatImage:any,CatImg:any): Observable<any> {
    return this.http.post(AUTH_API + 'addcategory', {
      CategoryName: t.CategoryName,
      CatImage: CatImage,
      CatImg: CatImg,
      IsActive: t.IsActive == true ? "Yes" : "No",
      CreatedBy:t.CreatedBy
    }, httpOptions);
  }

  updatecategory(t: {CategoryId:any;  CategoryName: any; IsActive: any;  CreatedBy: any;},CatImage:any,CatImg:any): Observable<any> {
    return this.http.post(AUTH_API + 'updatecategory', {
      CategoryId: t.CategoryId,
      CategoryName: t.CategoryName,
      CatImage: CatImage,
      CatImg: CatImg,
      IsActive: t.IsActive == true ? "Yes" : "No",
      CreatedBy:t.CreatedBy
    }, httpOptions);
  }

  getcategory(): Observable<any> {
    return this.http.get(AUTH_API + 'getcategory', httpOptions);
  }

  findcategoryById(CategoryId : number): Observable<any> {
    return this.http.get(AUTH_API + 'findById?CategoryId='+ CategoryId, httpOptions);
  }

  deletecategory(CategoryId : number): Observable<any> {
    return this.http.get(AUTH_API + 'deletecategory?CategoryId='+ CategoryId, httpOptions);
  }

  GetCatbyEventId(EventId : number,UserType:any): Observable<any> {
    return this.http.get(AUTH_API + 'GetCatbyEventId?EventId='+ EventId+"&UserType="+ UserType, httpOptions);
  }
  
  GetCategorybyEventId(EventId : number): Observable<any> {
    return this.http.get(AUTH_API + 'GetCategorybyEventId?EventId='+ EventId, httpOptions);
  }

  GetCategoryforRating(CategoryId : number): Observable<any> {
    return this.http.get(AUTH_API + 'GetCategoryforRating?CategoryId='+ CategoryId, httpOptions);
  }

  GetCategoryforRatinglistByCatId(CategoryId : number): Observable<any> {
    return this.http.get(AUTH_API + 'GetCategoryforRatinglistByCatId?CategoryId='+ CategoryId, httpOptions);
  }

  addcategoryrating(lst : any,CategoryId:any): Observable<any> {
    return this.http.post(AUTH_API + 'addcategoryrating', {
      lst: lst,
      CategoryId : CategoryId
    }, httpOptions);
  }

}
