<div class="container-fluid">
    <app-pagetitle title="Home Banner" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="tabindex" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-pen-square"></i></span>
                                <span class="d-none d-sm-block">Add Banner</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="validationform">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom23">Event Image</label>
                                                        <input #myInput type="file" class="form-control" id="validationCustom23" (change)="onFileChange($event)" accept="image/png,image/jpg, image/jpeg">

                                                        <img [src]="logoimgsrc" style="width:100px;" />
                                                    </div>
                                                </div>

                                            </div>
                                            <button class="btn btn-primary" type="submit">Save</button>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">Banner List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="BNId" [showBorders]="true">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column dataField="BannerImages" caption="Banner" [width]="250" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate">
                                                </dxi-column>
                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                                <div *dxTemplate="let data of 'cellTemplate'">
                                                    <img [src]="data.value" style="width:200px;" />
                                                </div>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav "></div>
                </div>
            </div>
        </div>
    </div>
</div>