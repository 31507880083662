import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserProfileService } from '../../core/services/user.service';
import { TokenStorageService } from '../../core/services/token-service.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';

@Component({
  selector: 'app-aboutmember',
  templateUrl: './aboutmember.component.html',
  styleUrls: ['./aboutmember.component.scss']
})
export class AboutmemberComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  currentFilter: any;
  applyFilterTypes:any;

  validationform: FormGroup;
  searchform: FormGroup;
  tabindex = 1;
  flag: number = 0;
  addflag: any = '';
  viewflag: any = 'active';
  userflag: any;
  datalist = [];
  eventlist = [];
  seventlist = [];
  temp = [];
  searchlist = [];
  logoimg: any;
  logoimgsrc: any;
  //ngbNav: NgbNav;
  constructor(public formBuilder: FormBuilder,
    private userService: UserProfileService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private tokenStorage: TokenStorageService) {
      this.applyFilterTypes = [{
        key: 'auto',
        name: 'Immediately',
      }, {
        key: 'onClick',
        name: 'On Button Click',
      }];
      this.currentFilter = this.applyFilterTypes[0].key;

     }

  // bread crumb items
  breadCrumbItems: Array<{}>;
  submit: boolean;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'SGTTA-SURAT' }, { label: 'About Member', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.validationform = this.formBuilder.group({
      MemberName: ['', [Validators.required]],
      AboutMember: ['', [Validators.required]],
      CatImage: [''],
      AMId: 0
    });




    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      processing: true
    };

    this.submit = false;
    this.getdata();

  }

  ngAfterViewInit(): void {
    // var datatable = $('#tbl').DataTable();
    // datatable.draw();
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // var datatable = $('#tbl').DataTable();
    // console.log(datatable);
    // datatable.destroy();
    // datatable.draw();
  }

  get form() {
    return this.validationform.controls;
  }

  get f() {
    return this.searchform.controls;
  }

  cleartext() {
    this.validationform = this.formBuilder.group({
      MemberName: ['', [Validators.required]],
      AboutMember: ['', [Validators.required]],
      CatImage: [''],
      AMId: 0
    });
    this.flag = 0;
    this.submit = false;
  }

  getdata() {
    // var datatable = $('#tbl').DataTable();
    // console.log(datatable);

    this.userService.getaboutmember().subscribe(
      (data: any) => {
        this.datalist = data;
      },
      (err: any) => {

      }
    );
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.logoimg = this.generate() + '_' + file.name;
      reader.readAsDataURL(file);
      var img = new Image();
      reader.onload = () => {
        img.src = reader.result as string;
      };

      img.onload = () => {
        this.logoimgsrc = this.imageToDataUri(img, 500, 500);
      }

    }
  }

  imageToDataUri(img, width, height) {

    // create an off-screen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
  }

  generate() {
    const characters = 'abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result +=
        characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  onSubmit(): void {
    this.submit = true;
    if (this.flag == 0) {

      this.userService.addAboutMember(this.validationform.value, this.logoimg, this.logoimgsrc).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.logoimgsrc = "";
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
    else {
      this.userService.updateAboutMember(this.validationform.value, this.logoimg, this.logoimgsrc).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.logoimgsrc = "";
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
  }


  edituserbyid(e) {
    console.log(e);
    this.userService.findAMById(e.row.data.AMId).subscribe(
      (data: any) => {
        console.log(data);
        var f = data;
        this.validationform.setValue({
          MemberName: f.MemberName,
          AboutMember: f.AboutMember,
          CatImage: '',
          AMId: f.AMId
        });
        this.logoimg = f.MemberImage;
        this.logoimgsrc = f.MemberImage == '' ? '' : this.sanitizer.bypassSecurityTrustUrl('http://api.sgtta.org/uploads/about/' + f.MemberImage);
        this.flag = 1;
        this.tabindex = 1;
        //this.nav.select(1);
      },
      (err: any) => {

      }
    );
  }

  deleteuser(e) {
    this.userService.deleteAboutMember(e.row.data.AMId).subscribe(
      (data: any) => {
        alert(data.message);
        this.getdata();
      },
      (err: any) => {

      }
    );
  }


  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'MemberList.xlsx');
      });
    });
    e.cancel = true;
  }


}
