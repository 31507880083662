<div class="container-fluid">
    <app-pagetitle title="Support" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="validationform">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Contact No</label>
                                    <input type="text" class="form-control" formControlName="MobileNo" placeholder="MobileNo" [ngClass]="{'is-invalid': submit && form.MobileNo.errors}">
                                    <div *ngIf="submit && form.MobileNo.errors" class="invalid-feedback">
                                        <span *ngIf="form.MobileNo.errors.required">Please provide
                                                                Contact No</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Email</label>
                                    <input type="text" class="form-control" formControlName="Email" placeholder="Email" [ngClass]="{'is-invalid': submit && form.Email.errors}">
                                    <div *ngIf="submit && form.MobileNo.errors" class="invalid-feedback">
                                        <span *ngIf="form.Email.errors.required">Please provide
                                                                Email</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Map</label>
                                    <textarea class="form-control" formControlName="Map" placeholder="Map" [ngClass]="{'is-invalid': submit && form.Map.errors}"></textarea>
                                    <div *ngIf="submit && form.Map.errors" class="invalid-feedback">
                                        <span *ngIf="form.Map.errors.required">Please provide
                                                                Map</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Address</label>
                                    <input type="text" class="form-control" formControlName="Address" placeholder="Address" [ngClass]="{'is-invalid': submit && form.Address.errors}">
                                    <div *ngIf="submit && form.Address.errors" class="invalid-feedback">
                                        <span *ngIf="form.Address.errors.required">Please provide
                                            Address</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Whatsapp No</label>
                                    <input type="text" class="form-control" formControlName="WhatsappNo" placeholder="WhatsappNo" [ngClass]="{'is-invalid': submit && form.WhatsappNo.errors}">
                                    <div *ngIf="submit && form.WhatsappNo.errors" class="invalid-feedback">
                                        <span *ngIf="form.WhatsappNo.errors.required">Please provide
                                            WhatsappNo</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Instagram</label>
                                    <input type="text" class="form-control" formControlName="Instagram" placeholder="Instagram" [ngClass]="{'is-invalid': submit && form.Instagram.errors}">
                                    <div *ngIf="submit && form.Instagram.errors" class="invalid-feedback">
                                        <span *ngIf="form.Instagram.errors.required">Please provide
                                            Instagram</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Facebook</label>
                                    <input type="text" class="form-control" formControlName="Facebook" placeholder="Facebook" [ngClass]="{'is-invalid': submit && form.Facebook.errors}">
                                    <div *ngIf="submit && form.Facebook.errors" class="invalid-feedback">
                                        <span *ngIf="form.Facebook.errors.required">Please provide
                                            Facebook</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Twitter</label>
                                    <input type="text" class="form-control" formControlName="Twitter" placeholder="Twitter" [ngClass]="{'is-invalid': submit && form.Twitter.errors}">
                                    <div *ngIf="submit && form.Twitter.errors" class="invalid-feedback">
                                        <span *ngIf="form.Twitter.errors.required">Please provide
                                            Twitter</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Linkedin</label>
                                    <input type="text" class="form-control" formControlName="Linkedin" placeholder="Linkedin" [ngClass]="{'is-invalid': submit && form.Linkedin.errors}">
                                    <div *ngIf="submit && form.Linkedin.errors" class="invalid-feedback">
                                        <span *ngIf="form.Linkedin.errors.required">Please provide
                                            Linkedin</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Youtube</label>
                                    <input type="text" class="form-control" formControlName="Youtube" placeholder="Youtube" [ngClass]="{'is-invalid': submit && form.Youtube.errors}">
                                    <div *ngIf="submit && form.Youtube.errors" class="invalid-feedback">
                                        <span *ngIf="form.Youtube.errors.required">Please provide
                                            Youtube</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button class="btn btn-primary" type="button" (click)="onSubmit()" [disabled]='validationform.invalid'>Save</button>
                </div>
            </div>
        </div>
    </div>
</div>