import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { CategoryService } from '../../core/services/category.service'

@Component({
  selector: 'app-categoryrating',
  templateUrl: './categoryrating.component.html',
  styleUrls: ['./categoryrating.component.scss']
})
export class CategoryratingComponent implements OnInit {
  datalist = [];
  currentFilter: any;
  applyFilterTypes: any;
  tasks: DataSource;
  validationform: FormGroup;
  selectAllModeVlaue = 'page';

  selectionModeValue = 'all';
  catlist = [];
  rcatlist = [];
  submit: boolean;
  breadCrumbItems: Array<{}>;
  tabindex = 1;
  constructor(public formBuilder: FormBuilder, public categoryService: CategoryService) {
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];
    this.currentFilter = this.applyFilterTypes[0].key;

  }
  get form() {
    return this.validationform.controls;
  }
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'SGTTA-SURAT' }, { label: 'Assign Category for Rating', active: true }];
    this.validationform = this.formBuilder.group({
      CategoryId: ['', [Validators.required]],
      CRId: 0
    });


    this.getcategory();
  }

  getcategory() {

    this.categoryService.getcategory().subscribe(
      (data: any) => {
        this.catlist = data;
      },
      (err: any) => {

      }
    );

  }

  getcatlist() {
    this. rcatlist = [];
    this.categoryService.GetCategoryforRating(this.validationform.controls['CategoryId'].value).subscribe(
      (data: any) => {
        this.tasks = new DataSource({
          store: new ArrayStore({
            key: 'id',
            data: data,
          }),
        });
      },
      (err: any) => {

      }
    );

    this.categoryService.GetCategoryforRatinglistByCatId(this.validationform.controls['CategoryId'].value).subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.rcatlist = data;
        }
      },
      (err: any) => {

      }
    );
  }

  onSubmit(): void {
    this.submit = true;
    console.log(this.rcatlist);
    // if (this.flag == 0) {

      this.categoryService.addcategoryrating(this.rcatlist, this.validationform.controls['CategoryId'].value).subscribe(
        (data: any) => {
          alert(data.message);
          this.rcatlist = [];
          this.validationform.reset();
          this.tasks = new DataSource({
            store: new ArrayStore({
              key: 'id',
              data: [],
            }),
          });
          this. rcatlist = [];
        },
        (err: any) => {

        }
      );
    // }
    // else {
    //   this.subcategoryService.updatesubcategory(this.validationform.value, this.logoimg, this.logoimgsrc).subscribe(
    //     (data: any) => {
    //       alert(data.message);
    //       this.getdata();
    //       this.validationform.reset();
    //       this.logoimgsrc = "";

    //       //this.cleartext();
    //       this.tabindex = 2;
    //     },
    //     (err: any) => {

    //     }
    //   );
    // }
  }


  edituserbyid(e) {
    //console.log(id);
    // this.subcategoryService.findsubcategoryById(e.row.data.SubcategoryId).subscribe(
    //   (data: any) => {
    //     //console.log(this.userflag);
    //     var f = data;
    //     this.validationform.setValue({
    //       SubcategoryName: f.SubcategoryName,
    //       CategoryId: f.CategoryId,
    //       SubcatImage: '',
    //       IsActive: f.IsActive == 'Yes' ? true : false,
    //       CreatedBy: this.userflag.id,
    //       SubcategoryId: f.SubcategoryId
    //     });
    //     this.logoimg = f.SubcatImage;
    //     this.logoimgsrc = f.SubcatImg == '' ? '' : this.sanitizer.bypassSecurityTrustUrl('http://localhost:8080/uploads/subcategory/' + f.SubcatImage);
    //     this.flag = 1;
    //     this.tabindex = 1;
    //     //this.nav.select(1);
    //   },
    //   (err: any) => {

    //   }
    // );
  }

  deleteuser(e) {
    // this.subcategoryService.deletesubcategory(e.row.data.SubcategoryId).subscribe(
    //   (data: any) => {
    //     alert(data.message);
    //     this.getdata();
    //   },
    //   (err: any) => {

    //   }
    // );
  }

}
