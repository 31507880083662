import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://api.sgtta.org/api/backstage/';
//const AUTH_API = 'http://localhost:8080/api/backstage/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class BackstageService {

  constructor(private http: HttpClient) { }

  addbackstage(t: { GuestId: any; PresenterId: any; CreatedBy: any; EventId:any;}): Observable<any> {
    return this.http.post(AUTH_API + 'add', {
      GuestId: t.GuestId,
      EventId: t.EventId,
      PresenterId: t.PresenterId,
      CreatedBy:t.CreatedBy
    }, httpOptions);
  }

  updatebackstage(t: {BcId:any;GuestId: any; PresenterId: any;EventId:any; ModifyBy: any; }): Observable<any> {
    return this.http.post(AUTH_API + 'update', {
      BcId: t.BcId,
      GuestId: t.GuestId,
      EventId: t.EventId,
      PresenterId: t.PresenterId,
      ModifyBy:t.ModifyBy
    }, httpOptions);
  }

  updatestatus(t: {BcId:any; ModifyBy: any; }): Observable<any> {
    return this.http.post(AUTH_API + 'updatestatus', {
      BcId: t.BcId,
      ModifyBy:t.ModifyBy
    }, httpOptions);
  }

  getbackstage(): Observable<any> {
    return this.http.get(AUTH_API + 'get', httpOptions);
  }

  Getlistforanchor(EventId : number): Observable<any> {
    return this.http.get(AUTH_API + 'Getlistforanchor?EventId='+ EventId, httpOptions);
  }

  deletebackstage(BcId : number): Observable<any> {
    return this.http.get(AUTH_API + 'delete?BcId='+ BcId, httpOptions);
  }

  findbackstageById(BcId : number): Observable<any> {
    return this.http.get(AUTH_API + 'findById?BcId='+ BcId, httpOptions);
  }

  GetBackstagebyEventId(EventId : number): Observable<any> {
    return this.http.get(AUTH_API + 'GetBackstagebyEventId?EventId='+ EventId, httpOptions);
  }
  
}
