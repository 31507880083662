<div class="container-fluid">
    <app-pagetitle title="Business" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="tabindex" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-pen-square"></i></span>
                                <span class="d-none d-sm-block">Add Business</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <form class="needs-validation" [formGroup]="validationform">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Registered Mobile No</label>
                                                        <input type="text" class="form-control" formControlName="RegisteredMobileNo" placeholder="Registered Mobile No" [ngClass]="{'is-invalid': submit && form.RegisteredMobileNo.errors}">
                                                        <div *ngIf="submit && form.RegisteredMobileNo.errors" class="invalid-feedback">
                                                            <span *ngIf="form.RegisteredMobileNo.errors.required">Please provide
                                                                a 
                                                                 Registered Mobile No</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">State</label>

                                                        <dx-select-box placeholder="Choose State" formControlName="State" [dataSource]="statelist" displayExpr="StateName" valueExpr="StateName" [searchMode]="searchModeOption" [searchExpr]="StatesearchExprOption" [searchTimeout]="searchTimeoutOption" [minSearchLength]="minSearchLengthOption"
                                                            [searchEnabled]="true" [showDataBeforeSearch]="showDataBeforeSearchOption" (onValueChanged)="onchange($event)" style="width: 100%; margin-left: 10px;"></dx-select-box>
                                                        <div *ngIf="submit && form.State.errors" class="invalid-feedback">
                                                            <span *ngIf="form.State.errors.required">Please select
                                                                State</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">City</label>

                                                        <dx-select-box placeholder="Choose City" formControlName="City" [dataSource]="citylist" displayExpr="CityName" valueExpr="CityName" [searchMode]="searchModeOption" [searchExpr]="CitysearchExprOption" [searchTimeout]="searchTimeoutOption" [minSearchLength]="minSearchLengthOption"
                                                            [searchEnabled]="true" [showDataBeforeSearch]="showDataBeforeSearchOption" style="width: 100%; margin-left: 10px;"></dx-select-box>
                                                        <div *ngIf="submit && form.City.errors" class="invalid-feedback">
                                                            <span *ngIf="form.City.errors.required">Please select 
                                                                City</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Category</label>
                                                        <dx-select-box placeholder="Choose Category" formControlName="CategoryId" [dataSource]="catlist" displayExpr="CategoryName" valueExpr="CategoryId" [searchMode]="searchModeOption" [searchExpr]="CatsearchExprOption" [searchTimeout]="searchTimeoutOption"
                                                            [minSearchLength]="minSearchLengthOption" [searchEnabled]="true" [showDataBeforeSearch]="showDataBeforeSearchOption" style="width: 100%; margin-left: 10px;"></dx-select-box>
                                                        <div *ngIf="submit && form.CategoryId.errors" class="invalid-feedback">
                                                            <span *ngIf="form.CategoryId.errors.required">Please select 
                                                                Category</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Product</label>
                                                        <dx-drop-down-box [(value)]="ProductId" displayExpr="Product" placeholder="Select a Product..." [showClearButton]="true" [deferRendering]="true" [dataSource]="productlist" style="width: 100%; margin-left: 10px;">
                                                            <div *dxTemplate="let data of 'content'">
                                                                <dx-data-grid [dataSource]="productlist" [columns]="['Product']" [selection]="{ mode: 'multiple' }" [hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 1000 }" [filterRow]="{ visible: true }" [scrolling]="{ mode: 'virtual' }" [height]="345"
                                                                    [(selectedRowKeys)]="ProductId">
                                                                </dx-data-grid>
                                                            </div>
                                                        </dx-drop-down-box>

                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Have you GST Number?</label>
                                                        <select class="form-control" formControlName="IsGST" [ngClass]="{'is-invalid': submit && form.IsGST.errors}">
                                                            <option  value="Yes">Yes</option>
                                                            <option  value="No">No</option>
                                                        </select>

                                                        <div *ngIf="submit && form.IsGST.errors" class="invalid-feedback">
                                                            <span *ngIf="form.IsGST.errors.required">Please select</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom01"> GSTIN</label>
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" formControlName="GSTIN" placeholder="GSTIN" [ngClass]="{'is-invalid': submit && form.GSTIN.errors}">
                                                            <button class="btn btn-success" (click)="onverify()">Verify</button>
                                                        </div>
                                                        <div *ngIf="submit && form.GSTIN.errors" class="invalid-feedback">
                                                            <span *ngIf="form.GSTIN.errors.required">Please provide
                                                                
                                                                GSTIN</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom01"> Pan card</label>
                                                        <input type="text" class="form-control" formControlName="PanCard" placeholder="PanCard" [ngClass]="{'is-invalid': submit && form.PanCard.errors}">
                                                        <div *ngIf="submit && form.PanCard.errors" class="invalid-feedback">
                                                            <span *ngIf="form.PanCard.errors.required">Please provide
                                                                Pancard</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom01"> Legal Name</label>
                                                        <input type="text" class="form-control" formControlName="LegalName" placeholder="LegalName" [ngClass]="{'is-invalid': submit && form.LegalName.errors}">
                                                        <div *ngIf="submit && form.LegalName.errors" class="invalid-feedback">
                                                            <span *ngIf="form.LegalName.errors.required">Please provide
                                                                LegalName</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom01"> Firm Name</label>
                                                        <input type="text" class="form-control" formControlName="FirmName" placeholder="FirmName" [ngClass]="{'is-invalid': submit && form.FirmName.errors}">
                                                        <div *ngIf="submit && form.FirmName.errors" class="invalid-feedback">
                                                            <span *ngIf="form.FirmName.errors.required">Please provide
                                                                FirmName</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom01"> Registered Address</label>
                                                        <input type="text" class="form-control" formControlName="RegisteredAddress" placeholder="Registered Address" [ngClass]="{'is-invalid': submit && form.RegisteredAddress.errors}">
                                                        <div *ngIf="submit && form.RegisteredAddress.errors" class="invalid-feedback">
                                                            <span *ngIf="form.RegisteredAddress.errors.required">Please provide
                                                                Registered Address</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom01"> GST Reg. Date</label>
                                                        <input type="date" class="form-control" formControlName="GSTRegDate" placeholder="GST Registered Date" [ngClass]="{'is-invalid': submit && form.GSTRegDate.errors}">
                                                        <div *ngIf="submit && form.GSTRegDate.errors" class="invalid-feedback">
                                                            <span *ngIf="form.GSTRegDate.errors.required">Please provide
                                                                
                                                               GST Registered Date</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom01"> Brand Name</label>
                                                        <input type="text" class="form-control" formControlName="BrandName" placeholder="BrandName" [ngClass]="{'is-invalid': submit && form.BrandName.errors}">
                                                        <div *ngIf="submit && form.BrandName.errors" class="invalid-feedback">
                                                            <span *ngIf="form.BrandName.errors.required">Please provide
                                                                
                                                                Brand Name</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Business Start Date</label>
                                                        <input type="date" class="form-control" formControlName="BusinessStartDate" placeholder="Business Start Date" [ngClass]="{'is-invalid': submit && form.BusinessStartDate.errors}">
                                                        <div *ngIf="submit && form.BusinessStartDate.errors" class="invalid-feedback">
                                                            <span *ngIf="form.BusinessStartDate.errors.required">Please provide
                                                                
                                                                Business Start Date</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom01"> Address</label>
                                                        <input type="text" class="form-control" formControlName="Address" placeholder="Address" [ngClass]="{'is-invalid': submit && form.Address.errors}">
                                                        <div *ngIf="submit && form.Address.errors" class="invalid-feedback">
                                                            <span *ngIf="form.Address.errors.required">Please provide
                                                                
                                                                Address</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom01"> Contact Person</label>
                                                        <input type="text" class="form-control" formControlName="ContactPerson" placeholder="Contact Person" [ngClass]="{'is-invalid': submit && form.ContactPerson.errors}">
                                                        <div *ngIf="submit && form.ContactPerson.errors" class="invalid-feedback">
                                                            <span *ngIf="form.ContactPerson.errors.required">Please provide
                                                                
                                                                Contact Person</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom01"> Mobile No</label>
                                                        <input type="text" class="form-control" formControlName="MobileNo" placeholder="MobileNo" [ngClass]="{'is-invalid': submit && form.MobileNo.errors}">
                                                        <div *ngIf="submit && form.MobileNo.errors" class="invalid-feedback">
                                                            <span *ngIf="form.MobileNo.errors.required">Please provide
                                                                a 
                                                                MobileNo</span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom23">Upload your Logo or Visiting Card</label>
                                                        <input #myInput type="file" class="form-control" id="validationCustom23" (change)="onFileChange($event)" accept="image/png,image/jpg, image/jpeg">

                                                        <img [src]="logoimgsrc" style="width:100px;" />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">

                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" formControlName="IsActive">
                                                            <label class="form-check-label" for="defaultCheck1">
                                                              Is Active
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">

                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" formControlName="IsVerify">
                                                            <label class="form-check-label" for="defaultCheck1">
                                                              Is Verify
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <form [formGroup]="branchForm">
                                            <div class="row">

                                                <div class="col-md-4">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h4> Add Branch</h4>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="validationCustom01"> Address</label>
                                                                    <input type="text" class="form-control" formControlName="Address" placeholder="Address" [ngClass]="{'is-invalid': submit && bform.Address.errors}">
                                                                    <div *ngIf="submit && bform.Address.errors" class="invalid-feedback">
                                                                        <span *ngIf="bform.Address.errors.required">Please provide
                                                                    
                                                                    Address</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="validationCustom01"> Contact Person</label>
                                                                    <input type="text" class="form-control" formControlName="ContactPerson" placeholder="Contact Person" [ngClass]="{'is-invalid': submit && bform.ContactPerson.errors}">
                                                                    <div *ngIf="submit && bform.ContactPerson.errors" class="invalid-feedback">
                                                                        <span *ngIf="bform.ContactPerson.errors.required">Please provide
                                                                    
                                                                    Contact Person</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label for="validationCustom01"> Mobile No</label>
                                                                    <input type="text" class="form-control" formControlName="MobileNo" placeholder="MobileNo" [ngClass]="{'is-invalid': submit && bform.MobileNo.errors}">
                                                                    <div *ngIf="submit && bform.MobileNo.errors" class="invalid-feedback">
                                                                        <span *ngIf="bform.MobileNo.errors.required">Please provide
                                                                    a 
                                                                    MobileNo</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button class="btn btn-success" type="submit" (click)="addbranch()" [disabled]='branchForm.invalid'>Add Branch</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-8">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="table-responsive">
                                                                <table class="table table-bordered table-sm m-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>#</th>
                                                                            <th>Contact Person</th>
                                                                            <th>Mobile No</th>
                                                                            <th>Address</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr *ngFor="let g of branchlist">
                                                                            <td scope="row">
                                                                                <button class="btn btn-success btn-sm" (click)="edituserbyid(g.UserId)"><i class="fas fa-edit"></i></button>

                                                                            </td>
                                                                            <td scope="row">

                                                                                <button class="btn btn-danger btn-sm" (click)="deleteuser(g.UserId)"><i class="fas fa-window-close"></i></button>
                                                                            </td>
                                                                            <td>{{g.ContactPerson}}</td>
                                                                            <td>{{g.MobileNo}}</td>
                                                                            <td>{{g.Address}}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <!-- <ul>
                                            <li>Is my form valid? {{validationform.valid}}</li>
                                            <li><pre>My GSTIN field errors:
                    {{validationform.controls.GSTIN.errors| json}}</pre></li>
                                            <li><pre>My PanCard field errors:
                        {{validationform.controls.PanCard.errors| json}}</pre></li>
                                            <li><pre>My RegisteredAddress field errors:
                            {{validationform.controls.RegisteredAddress.errors| json}}</pre></li>
                                            <li><pre>My form errors: 
            {{validationform.errors| json}}</pre></li>
                                            <li>Form value: {{validationform.value|json}}</li>
                                            <li>Form value: {{validationform.controls.RegisteredAddress.errors.required |json}}</li>
                                        </ul> -->
                                        <button class="btn btn-primary" type="button" (click)="onSubmit()" [disabled]='validationform.invalid'>Save</button>

                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">Business List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">

                                        <div class="table-responsive">
                                            <!--  -->
                                            <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="BusinessId" [showBorders]="true">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <!-- <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column> -->
                                                <dxi-column dataField="BrandName" caption="Brand Name" [width]="300" dataType="string"></dxi-column>
                                                <dxi-column dataField="MobileNo" caption="Mobile No" [width]="200" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="State" caption="State" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="City" caption="City" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="IsActive" caption="Is Active" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="IsVerify" caption="Is Verify" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="BusinessImg" caption="Business Image" [width]="150" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate">
                                                </dxi-column>
                                                <dxi-column dataField="CreatedOn" caption="CreatedOn" [width]="100" dataType="date" format="dd/MM/yyyy hh:mm:ss">
                                                </dxi-column>
                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                                <div *dxTemplate="let data of 'cellTemplate'">
                                                    <img [src]="data.value" style="width:100px;" />
                                                </div>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">Pending Verification Business List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">

                                        <div class="table-responsive">
                                            <!--  -->
                                            <dx-data-grid id="gridContainer" [dataSource]="pendingdatalist" keyExpr="BusinessId" [showBorders]="true">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <!-- <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column> -->
                                                <dxi-column dataField="BrandName" caption="Brand Name" [width]="300" dataType="string"></dxi-column>
                                                <dxi-column dataField="MobileNo" caption="Mobile No" [width]="200" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="State" caption="State" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="City" caption="City" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="IsActive" caption="Is Active" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="IsVerify" caption="Is Verify" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="BusinessImg" caption="Business Image" [width]="150" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate">
                                                </dxi-column>
                                                <dxi-column dataField="CreatedOn" caption="CreatedOn" [width]="100" dataType="date" format="dd/MM/yyyy hh:mm:ss">
                                                </dxi-column>
                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                                <div *dxTemplate="let data of 'cellTemplate'">
                                                    <img [src]="data.value" style="width:100px;" />
                                                </div>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="4">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">Verified Business List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">

                                        <div class="table-responsive">
                                            <!--  -->
                                            <dx-data-grid id="gridContainer" [dataSource]="verifylist" keyExpr="BusinessId" [showBorders]="true">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <!-- <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column> -->
                                                <dxi-column dataField="BrandName" caption="Brand Name" [width]="300" dataType="string"></dxi-column>
                                                <dxi-column dataField="MobileNo" caption="Mobile No" [width]="200" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="State" caption="State" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="City" caption="City" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="IsActive" caption="Is Active" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="IsVerify" caption="Is Verify" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="BusinessImg" caption="Business Image" [width]="150" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate">
                                                </dxi-column>
                                                <dxi-column dataField="CreatedOn" caption="CreatedOn" [width]="100" dataType="date" format="dd/MM/yyyy hh:mm:ss">
                                                </dxi-column>
                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                                <div *dxTemplate="let data of 'cellTemplate'">
                                                    <img [src]="data.value" style="width:100px;" />
                                                </div>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="5">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">Active Business List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">

                                        <div class="table-responsive">
                                            <!--  -->
                                            <dx-data-grid id="gridContainer" [dataSource]="activelist" keyExpr="BusinessId" [showBorders]="true">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <!-- <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column> -->
                                                <dxi-column dataField="BrandName" caption="Brand Name" [width]="300" dataType="string"></dxi-column>
                                                <dxi-column dataField="MobileNo" caption="Mobile No" [width]="200" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="State" caption="State" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="City" caption="City" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="IsActive" caption="Is Active" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="IsVerify" caption="Is Verify" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="BusinessImg" caption="Business Image" [width]="150" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate">
                                                </dxi-column>
                                                <dxi-column dataField="CreatedOn" caption="CreatedOn" [width]="100" dataType="date" format="dd/MM/yyyy hh:mm:ss">
                                                </dxi-column>
                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                                <div *dxTemplate="let data of 'cellTemplate'">
                                                    <img [src]="data.value" style="width:100px;" />
                                                </div>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="6">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">InActive Business List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">

                                        <div class="table-responsive">
                                            <!--  -->
                                            <dx-data-grid id="gridContainer" [dataSource]="inactivelist" keyExpr="BusinessId" [showBorders]="true">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <!-- <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column> -->
                                                <dxi-column dataField="BrandName" caption="Brand Name" [width]="300" dataType="string"></dxi-column>
                                                <dxi-column dataField="MobileNo" caption="Mobile No" [width]="200" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="State" caption="State" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="City" caption="City" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="IsActive" caption="Is Active" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="IsVerify" caption="Is Verify" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="BusinessImg" caption="Business Image" [width]="150" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate">
                                                </dxi-column>
                                                <dxi-column dataField="CreatedOn" caption="CreatedOn" [width]="100" dataType="date" format="dd/MM/yyyy hh:mm:ss">
                                                </dxi-column>
                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                                <div *dxTemplate="let data of 'cellTemplate'">
                                                    <img [src]="data.value" style="width:100px;" />
                                                </div>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
    </div>
</div>