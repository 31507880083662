import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ValidatorFn } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CategoryService } from '../../core/services/category.service';
import { UserProfileService } from '../../core/services/user.service';
import { TokenStorageService } from '../../core/services/token-service.service';
import { SubcategoryService } from '../../core/services/subcategory.service';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';
import { Console } from 'console';

export interface BooleanFn {
  (): boolean;
}
export function conditionalValidator(predicate: BooleanFn,
  validator: ValidatorFn,
  errorNamespace?: string): ValidatorFn {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (predicate()) {
      error = validator(formControl);
    }
    if (errorNamespace && error) {
      const customError = {};
      customError[errorNamespace] = error;
      error = customError
    }
    return error;
  })
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @ViewChild('myInput') myInputVariable: any;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  ProductId = [];
  currentFilter: any;
  applyFilterTypes: any;
  branchForm: FormGroup;
  validationform: FormGroup;
  tabindex = 1;
  flag: number = 0;
  addflag: any = '';
  viewflag: any = 'active';
  userflag: any;
  datalist = [];
  pendingdatalist = [];
  verifylist = [];
  activelist = [];
  inactivelist = [];
  catlist = [];
  productlist = [];
  branchlist = [];
  dbranchlist = [];
  statelist = [];
  citylist = [];
  index;
  logoimgsrc: any;
  logoimg: any;
  businessid;
  addr;
  searchModeOption = 'contains';

  StatesearchExprOption: any = 'StateName';

  CitysearchExprOption: any = 'CityName';

  CatsearchExprOption: any = 'CategoryName';

  SubsearchExprOption: any = 'SubcategoryName';

  searchTimeoutOption = 200;

  minSearchLengthOption = 0;

  showDataBeforeSearchOption = false;

  //ngbNav: NgbNav;
  constructor(public formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private userService: UserProfileService,
    private subcatService: SubcategoryService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private tokenStorage: TokenStorageService) {
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];
    this.currentFilter = this.applyFilterTypes[0].key;

    this.validationform = this.formBuilder.group({
      RegisteredMobileNo: ['', [Validators.required, Validators.pattern("^[6-9][0-9]{9}$")]],
      State: ['', [Validators.required]],
      City: ['', [Validators.required]],
      CategoryId: ['', [Validators.required]],
      IsGST: ["Yes", [Validators.required]],
      GSTIN: ["", [conditionalValidator(() => (this.validationform.get('IsGST').value == "No" ? false : true),
        Validators.required,
        'illuminatiError'), Validators.pattern('[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz1-9A-Ja-j]{1}[0-9a-zA-Z]{1}')]],
      PanCard: ["", [conditionalValidator(() => (this.validationform.get('IsGST').value == "No" ? true : false),
        Validators.required,
        'illuminatiError'), Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')]],
      LegalName: ["", [conditionalValidator(() => (this.validationform.get('IsGST').value == "No" ? false : true),
      Validators.required,
      'illuminatiError')]],
      FirmName: ["", [conditionalValidator(() => (this.validationform.get('IsGST').value == "No" ? false : true),
      Validators.required,
      'illuminatiError')]],
      RegisteredAddress: ["", [conditionalValidator(() => (this.validationform.get('IsGST').value == "No" ? false : true),
        Validators.required,
        'illuminatiError')]],
      GSTRegDate: ["", [conditionalValidator(() => (this.validationform.get('IsGST').value == "No" ? false : true),
        Validators.required,
        'illuminatiError')]],
      BrandName: ["", [Validators.required]],
      BusinessStartDate: [""],
      Address: [""],
      ContactPerson: [""],
      MobileNo: ["", [Validators.pattern("^[6-9][0-9]{9}$")]],
      IsActive: [false, [Validators.required]],
      IsVerify: [false, [Validators.required]]
    });

    this.validationform.get('IsGST').valueChanges
      .subscribe(value => {
        this.validationform.get('GSTIN').updateValueAndValidity();
        this.validationform.get('PanCard').updateValueAndValidity();
        this.validationform.get('RegisteredAddress').updateValueAndValidity();
        this.validationform.get('GSTRegDate').updateValueAndValidity();
        this.validationform.get('LegalName').updateValueAndValidity();
        this.validationform.get('FirmName').updateValueAndValidity();
      });

    this.branchForm = this.formBuilder.group({
      Address: ["", [Validators.required]],
      ContactPerson: ["", [Validators.required]],
      MobileNo: ["", [Validators.required, Validators.pattern("^[6-9][0-9]{9}$")]]
    });
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  submit: boolean;
  dtOptions: any = {};
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'SGTTA-SURAT' }, { label: 'Business', active: true }];
    this.userflag = this.tokenStorage.getUser();


    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
      buttons: [
        'copy', 'csv', 'excel', 'print'
      ]
    };

    this.submit = false;
    this.getdata();

  }

  get form() {
    return this.validationform.controls;
  }

  get bform() {
    return this.branchForm.controls;
  }

  getdata() {
    this.userService.GetBusinessList().subscribe(
      (data: any) => {
        this.datalist = data;
      },
      (err: any) => {

      }
    );

    this.userService.GetPendingVerificationBusinessList('Pending').subscribe(
      (data: any) => {
        this.pendingdatalist = data;
      },
      (err: any) => {

      }
    );

    this.userService.GetPendingVerificationBusinessList('Verified').subscribe(
      (data: any) => {
        this.verifylist = data;
      },
      (err: any) => {

      }
    );

    this.userService.GetPendingVerificationBusinessList('Active').subscribe(
      (data: any) => {
        this.activelist = data;
      },
      (err: any) => {

      }
    );

    this.userService.GetPendingVerificationBusinessList('InActive').subscribe(
      (data: any) => {
        this.inactivelist = data;
      },
      (err: any) => {

      }
    );

    this.categoryService.getcategory().subscribe(
      (data: any) => {
        this.catlist = data;
      },
      (err: any) => {

      }
    );

    this.userService.GetProductListAdmin().subscribe(
      (data: any) => {
        console.log(data);
        this.productlist = data;
      },
      (err: any) => {

      });


    this.userService.getstatelist().subscribe(
      (data: any) => {
        this.statelist = data;
      },
      (err: any) => {

      }
    );

  }

  displayValueFormatter(value) {
    console.log(value);
    console.log(Array.isArray(value) ? value.join("; ") : value);
    return Array.isArray(value) ? value.join("; ") : value;
  }

  setdisplytext(item) {
    console.log(item);
    if (Array.isArray(item)) {
      var text = '';
      for (let i = 0; i < item.length; i++) {
        text = text + item[i].Product + ',';
      }
      return text;
    }
    else {
      return "";
    }
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.logoimg = this.generate() + '_' + file.name;
      reader.readAsDataURL(file);
      var img = new Image();
      reader.onload = () => {
        img.src = reader.result as string;
      };

      img.onload = () => {
        this.logoimgsrc = this.imageToDataUri(img, 500, 500);
      }

    }
  }

  imageToDataUri(img, width, height) {

    // create an off-screen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
  }

  generate() {
    const characters = 'abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  addbranch() {
    if (this.flag == 0) {
      this.branchlist.push({ BBId: 0, BusinessId: 0, ContactPerson: this.branchForm.controls['ContactPerson'].value, MobileNo: this.branchForm.controls['MobileNo'].value, Address: this.branchForm.controls['Address'].value });
      this.branchForm.reset();
      this.flag = 0;
    }
    else {
      this.branchlist[this.index].ContactPerson = this.branchForm.controls['ContactPerson'].value;
      this.branchlist[this.index].MobileNo = this.branchForm.controls['MobileNo'].value;
      this.branchlist[this.index].Address = this.branchForm.controls['Address'].value;
      this.branchForm.reset();
      this.flag = 0;
    }

  }

  editbranch(i, d) {
    this.index = i;
    this.flag = 1;
    this.branchForm.controls['ContactPerson'].setValue(d.ContactPerson);
    this.branchForm.controls['MobileNo'].setValue(d.MobileNo);
    this.branchForm.controls['Address'].setValue(d.Address);
  }

  removebranch(d) {

    const index: number = this.branchlist.indexOf(d);
    if (index !== -1) {
      this.dbranchlist.push(this.branchlist[index]);
      this.branchlist.splice(index, 1);
    }
  }

  onSubmit(): void {
    this.submit = true;
    console.log(this.ProductId);
    var subcatid = '';
    for (let i = 0; i < this.ProductId.length; i++) {
      subcatid = subcatid + this.ProductId[i].id + ',';
    }

    var t = {
      RegisteredMobileNo: this.validationform.controls['RegisteredMobileNo'].value,
      MobileNo: this.validationform.controls['MobileNo'].value,
      State: this.validationform.controls['State'].value,
      City: this.validationform.controls['City'].value,
      CategoryId: this.validationform.controls['CategoryId'].value,
      SubCategoryId: subcatid, //this.validationform.controls['SubCategoryId'].value,
      IsGST: this.validationform.controls['IsGST'].value,
      GSTIN: this.validationform.controls['GSTIN'].value,
      PanCard: this.validationform.controls['PanCard'].value,
      LegalName: this.validationform.controls['LegalName'].value,
      FirmName: this.validationform.controls['FirmName'].value,
      RegisteredAddress: this.validationform.controls['RegisteredAddress'].value,
      GSTRegDate: this.validationform.controls['GSTRegDate'].value,
      BrandName: this.validationform.controls['BrandName'].value,
      BusinessStartDate: this.validationform.controls['BusinessStartDate'].value,
      Address: this.validationform.controls['Address'].value,
      ContactPerson: this.validationform.controls['ContactPerson'].value,
      IsActive: this.validationform.controls['IsActive'].value == true ? "Yes" : "No",
      IsVerify: this.validationform.controls['IsVerify'].value == true ? "Yes" : "No",
      CreatedBy: this.userflag.id,
      UserId: 0,
      RefBy: 0,
      BusinessId: this.businessid
    }
    if (this.flag == 0) {
      this.userService.addBusinessAdmin(t, this.logoimg, this.logoimgsrc, this.branchlist).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.myInputVariable.nativeElement.value = "";
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
    else {
      this.userService.UpdateCustomer(t, this.logoimg, this.logoimgsrc, this.branchlist, this.dbranchlist).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.myInputVariable.nativeElement.value = "";
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
  }


  edituserbyid(e) {
    //console.log(id);
    this.userService.GetBusinessListbyBusinessId(e.row.data.BusinessId).subscribe(
      (data: any) => {
        console.log(data);
        var f = data[0];
        this.validationform.setValue({
          RegisteredMobileNo: f.RegisteredMobileNo,
          State: f.State,
          City: f.City,
          CategoryId: f.CategoryId,
          IsGST: f.IsGST,
          GSTIN: f.GSTIN,
          PanCard: f.PanCard,
          LegalName: f.LegalName,
          FirmName: f.FirmName,
          GSTRegDate: this.formatDate(f.GSTRegDate),
          RegisteredAddress: f.RegisteredAddress,
          BrandName: f.BrandName,
          Address: f.Address,
          ContactPerson: f.ContactPerson,
          BusinessStartDate: this.formatDate(f.BusinessStartDate),
          MobileNo: f.MobileNo,
          IsActive: f.IsActive == "Yes" ? true : false,
          IsVerify: f.IsVerify == "Yes" ? true : false
        });
        this.businessid = f.BusinessId;
        this.userService.getcitylist(this.validationform.controls['State'].value).subscribe(
          (data: any) => {
            this.citylist = data;
            this.validationform.controls['City'].setValue(f.City);
          },
          (err: any) => {

          }
        );

        this.userService.GetBranchListbyBusinessId(e.row.data.BusinessId).subscribe(
          (data: any) => {
            this.branchlist = data;
          },
          (err: any) => {

          }
        );

        this.userService.GetProductListbyBusinessId(e.row.data.BusinessId).subscribe(
          (data: any) => {
            this.ProductId = data;
          },
          (err: any) => {

          }
        );

        this.flag = 1;
        this.tabindex = 1;
        this.logoimgsrc = this.sanitizer.bypassSecurityTrustUrl('http://api.sgtta.org/uploads/business/' + f.BusinessImage);

        //this.nav.select(1);
      },
      (err: any) => {

      }
    );
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  deleteuser(e) {
    this.userService.deleteuser(e.row.data.UserId).subscribe(
      (data: any) => {
        alert(data.message);
        this.getdata();
      },
      (err: any) => {

      }
    );
  }

  onchange(e: any) {

    this.userService.getcitylist(e.value).subscribe(
      (data: any) => {
        this.citylist = data;
      },
      (err: any) => {

      }
    );


  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'UserList.xlsx');
      });
    });
    e.cancel = true;
  }

  onverify() {
    this.userService.verifyGSTIN(this.validationform.controls['GSTIN'].value.toUpperCase()).subscribe(
      (data: any) => {
        console.log(data);
        if (data.message == "Success") {
          var r = data.gstdata.data;
          this.validationform.controls['LegalName'].setValue(r.lgnm);
          this.validationform.controls['PanCard'].setValue(r.gstin.substring(2, 12));
          console.log(r.rgdt);
          // var dt = new Date(Date.UTC(r.rgdt.split('/')[2],r.rgdt.split('/')[1]-1,r.rgdt.split('/')[0],0,0,0)) ;
          var dt = r.rgdt.split('/')[2] + '-' + r.rgdt.split('/')[1] + '-' + r.rgdt.split('/')[0];
          console.log(dt);
          this.validationform.controls['GSTRegDate'].setValue(dt);
          this.validationform.controls['FirmName'].setValue(r.tradeNam);
          this.addr = (r.pradr.addr.flno != undefined && r.pradr.addr.flno != null && r.pradr.addr.flno != '' ? r.pradr.addr.flno : '') + ' ' + (r.pradr.addr.bno != undefined && r.pradr.addr.bno != null && r.pradr.addr.bno != '' ? r.pradr.addr.bno : '') + ' ' + (r.pradr.addr.bnm != null && r.pradr.addr.bnm != undefined && r.pradr.addr.bnm != '' ? r.pradr.addr.bnm : '') + ' ' + (r.pradr.addr.st != undefined && r.pradr.addr.st != null && r.pradr.addr.st != '' ? r.pradr.addr.st : '') + ' ' + (r.pradr.addr.dst != undefined && r.pradr.addr.dst != null && r.pradr.addr.dst != '' ? r.pradr.addr.dst : '') + '-' + (r.pradr.addr.pncd != undefined && r.pradr.addr.pncd != null && r.pradr.addr.pncd != '' ? r.pradr.addr.pncd : '') + ' ' + (r.pradr.addr.stcd != undefined && r.pradr.addr.stcd != null && r.pradr.addr.stcd != '' ? r.pradr.addr.stcd : '');
          console.log(this.addr);
          this.validationform.controls['RegisteredAddress'].setValue(this.addr);
        }
        else {
          this.validationform.reset();
        }

      },
      (err: any) => {
      });
  }

}
