import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://api.sgtta.org/api/news/';
//const AUTH_API = 'http://localhost:8080/api/news/';
const AUTH_API1 = 'http://api.sgtta.org/api/event/';
//const AUTH_API1 = 'http://localhost:8080/api/event/';
const AUTH_API2 = 'http://api.sgtta.org/api/note/';
//const AUTH_API2 = 'http://localhost:8080/api/note/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EventservService {

  constructor(private http: HttpClient) { }

  addnews(NewsPDF: any, NewsPDFData: any): Observable<any> {
    return this.http.post(AUTH_API + 'add', {
      NewsPDF: NewsPDF,
      NewsPDFData: NewsPDFData
    }, httpOptions);
  }


  getnews(): Observable<any> {
    return this.http.get(AUTH_API + 'get', httpOptions);
  }

  findnewsById(NId: number): Observable<any> {
    return this.http.get(AUTH_API + 'findById?NId=' + NId, httpOptions);
  }

  deletenews(EventId: number): Observable<any> {
    return this.http.get(AUTH_API + 'delete?NId=' + EventId, httpOptions);
  }


  addevent(t: { EventName: any; Description: any; EventDate: any;}, EventImage: any, EventImg: any): Observable<any> {
    return this.http.post(AUTH_API1 + 'addevent', {
      EventName: t.EventName,
      Description: t.Description,
      EventDate: t.EventDate,
      EventImage: EventImage,
      EventImg: EventImg
    }, httpOptions);
  }

  updateevent(t: { EventId: any; EventName: any; Description: any; EventDate: any; },EventImage: any, EventImg: any): Observable<any> {
    return this.http.post(AUTH_API1 + 'updateevent', {
      EventId: t.EventId,
      EventName: t.EventName,
      Description: t.Description,
      EventDate: t.EventDate,
      EventImage: EventImage,
      EventImg: EventImg
    }, httpOptions);
  }

  getevent(): Observable<any> {
    return this.http.get(AUTH_API1 + 'getevent', httpOptions);
  }

  findeventById(EventId: number): Observable<any> {
    return this.http.get(AUTH_API1 + 'findeventById?EventId=' + EventId, httpOptions);
  }

  deleteevent(EventId: number): Observable<any> {
    return this.http.get(AUTH_API1 + 'deleteevent?EventId=' + EventId, httpOptions);
  }

  addnote(t: { Name: any; Description: any; NoteDate: any;}, NoteImage: any, NoteImg: any): Observable<any> {
    return this.http.post(AUTH_API2 + 'addnote', {
      Name: t.Name,
      Description: t.Description,
      NoteDate: t.NoteDate,
      NoteImage: NoteImage,
      NoteImg: NoteImg
    }, httpOptions);
  }

  updatenote(t: { NoteId: any; Name: any; Description: any; NoteDate: any; },NoteImage: any, NoteImg: any): Observable<any> {
    return this.http.post(AUTH_API2 + 'updateevent', {
      NoteId: t.NoteId,
      Name: t.Name,
      Description: t.Description,
      NoteDate: t.NoteDate,
      NoteImage: NoteImage,
      NoteImg: NoteImg
    }, httpOptions);
  }

  getnote(): Observable<any> {
    return this.http.get(AUTH_API2 + 'getnote', httpOptions);
  }

  findnoteById(NoteId: number): Observable<any> {
    return this.http.get(AUTH_API2 + 'findnoteById?NoteId=' + NoteId, httpOptions);
  }

  deletenote(NoteId: number): Observable<any> {
    return this.http.get(AUTH_API2 + 'deletenote?NoteId=' + NoteId, httpOptions);
  }

}
