import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CategoryService } from '../../core/services/category.service';
import { SubcategoryService } from '../../core/services/subcategory.service';
import { GuestService } from '../../core/services/guest.service';
import { TokenStorageService } from '../../core/services/token-service.service';
import { EventservService } from '../../core/services/eventserv.service';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit {
  @ViewChild('myInput') myInputVariable: any;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  currentFilter: any;
  applyFilterTypes:any;

  validationform: FormGroup;
  tabindex = 1;
  flag: number = 0;
  addflag: any = '';
  viewflag: any = 'active';
  userflag: any;
  datalist = [];
  catlist = [];
  eventlist = [];
  subcatlist = [];
  logoimgsrc: any;
  logoimg: any;
  //ngbNav: NgbNav;
  constructor(public formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService,
    private guestService: GuestService,
    private eventService: EventservService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private tokenStorage: TokenStorageService) {
       this.applyFilterTypes = [{
        key: 'auto',
        name: 'Immediately',
      }, {
        key: 'onClick',
        name: 'On Button Click',
      }];
      this.currentFilter = this.applyFilterTypes[0].key;
     }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  submit: boolean;
  dtOptions: any = {};
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'EVENT' }, { label: 'Guest', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.validationform = this.formBuilder.group({
      Name: ['', [Validators.required]],
      CategoryId: ['', [Validators.required]],
      SubCategoryId: [''],
      EventId: ['', [Validators.required]],
      About: ['', [Validators.required]],
      CreatedBy: [this.userflag.id],
      GuestId: 0
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
      buttons: [
        'copy', 'csv', 'excel', 'print'
      ]
    };

    this.submit = false;
    this.getdata();

  }

  get form() {
    return this.validationform.controls;
  }

  cleartext() {
    this.validationform.setValue({
      Name: ['', [Validators.required]],
      CategoryId: ['', [Validators.required]],
      SubCategoryId: [''],
      EventId: ['', [Validators.required]],
      About: ['', [Validators.required]],
      CreatedBy: [this.userflag.id],
      GuestId: 0
    });
    this.flag = 0;
    this.submit = false;
  }

  getdata() {
    var datatable = $('#tbl').DataTable();
    console.log(datatable);
    datatable.destroy();
    // this.guestService.getguest().subscribe(
    //   (data: any) => {
    //     this.datalist = data;
    //   },
    //   (err: any) => {

    //   }
    // );

   

    // this.eventService.getevent().subscribe(
    //   (data: any) => {
    //     this.eventlist = data;
    //   },
    //   (err: any) => {

    //   }
    // );
  }

  getcategory(value){
    this.categoryService.GetCatbyEventId(value,'Guest').subscribe(
      (data: any) => {
        this.catlist = data;
      },
      (err: any) => {

      }
    );
  }

  onchange(value){
    this.subcategoryService.GetSubcatbyCatId(value,'Guest').subscribe(
      (data: any) => {
        this.subcatlist = data;
      },
      (err: any) => {

      }
    );
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.logoimg = this.generate() + '_' + file.name;
      reader.readAsDataURL(file);
      var img = new Image();
      reader.onload = () => {
        img.src = reader.result as string;
      };

      img.onload = () => {
        this.logoimgsrc = this.imageToDataUri(img, 500, 500);
      }

    }
  }

  imageToDataUri(img, width, height) {

    // create an off-screen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
  }

  generate() {
    const characters = 'abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result +=
        characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  onSubmit(): void {
    this.submit = true;
    if (this.flag == 0) {

      // this.guestService.addguest(this.validationform.value, this.logoimg, this.logoimgsrc).subscribe(
      //   (data: any) => {
      //     alert(data.message);
      //     this.getdata();
      //     this.validationform.reset();
      //     this.logoimg = '';
      //     this.logoimgsrc = '';
      //     this.myInputVariable.nativeElement.value = "";
      //     //this.cleartext();
      //     this.tabindex = 1;
      //   },
      //   (err: any) => {

      //   }
      // );
    }
    else {
      this.guestService.updateguest(this.validationform.value, this.logoimg, this.logoimgsrc).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.logoimg = '';
          this.logoimgsrc = '';
          this.myInputVariable.nativeElement.value = "";
          //this.cleartext();
          this.tabindex = 1;
        },
        (err: any) => {

        }
      );
    }
  }


  edituserbyid(e) {
    //console.log(id);
    this.guestService.findguestById(e.row.data.GuestId).subscribe(
      (data: any) => {
        //console.log(this.userflag);
        var f = data;
        this.validationform.setValue({
          Name: f.Name,
          CategoryId: f.CategoryId,
          SubCategoryId: f.SubCategoryId,
          EventId: f.EventId,
          About: f.About,
          CreatedBy: this.userflag.id,
          GuestId: f.GuestId
        });
        this.categoryService.GetCatbyEventId(f.EventId,'Guest').subscribe(
          (data: any) => {
            this.catlist = data;
            this.validationform.controls["CategoryId"].setValue(f.CategoryId);
          },
          (err: any) => {
    
          }
        );

        this.subcategoryService.GetSubcatbyCatId(f.CategoryId,'Guest').subscribe(
          (data: any) => {
            this.subcatlist = data;
            this.validationform.controls["SubCategoryId"].setValue(f.SubCategoryId);
          },
          (err: any) => {
    
          }
        );
        this.logoimgsrc = this.sanitizer.bypassSecurityTrustUrl('http://eventapi.event.airconinfotech.com/uploads/guest/'+ f.GuestImage);
        this.flag = 1;
        this.tabindex = 1;
        //this.nav.select(1);
      },
      (err: any) => {

      }
    );
  }

  deleteuser(e) {
    this.guestService.deleteguest(e.row.data.GuestId).subscribe(
      (data: any) => {
        alert(data.message);
        this.getdata();
      },
      (err: any) => {

      }
    );
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'GuestList.xlsx');
      });
    });
    e.cancel = true;
  }

}
