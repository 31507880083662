import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ValidatorFn } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserProfileService } from '../../core/services/user.service';
import { TokenStorageService } from '../../core/services/token-service.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  validationform : FormGroup;
  applyFilterTypes;
  currentFilter;
  userflag;
  constructor(public formBuilder: FormBuilder,
    private userService: UserProfileService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private tokenStorage: TokenStorageService) {
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];
    this.currentFilter = this.applyFilterTypes[0].key;

    this.validationform = this.formBuilder.group({
      Email: [""],
      Map: [""],
      MobileNo: ["", [ Validators.pattern("^[6-9][0-9]{9}$")]],
      Address: [""],
      WhatsappNo: [""],
      Instagram: [""],
      Facebook: [""],
      Linkedin: [""],
      Twitter: [""],
      Youtube: [""]
    });

  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  submit: boolean;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'SGTTA-SURAT' }, { label: 'Business', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.submit = false;
this.getdata();
  }

  get form() {
    return this.validationform.controls;
  }
  
  getdata() {
    this.userService.GetSupport().subscribe(
      (data: any) => {
        var t = data[0];
        this.validationform.controls['Map'].setValue(t.Map);
        this.validationform.controls['MobileNo'].setValue(t.MobileNo);
        this.validationform.controls['Email'].setValue(t.Email);
        this.validationform.controls['Address'].setValue(t.Address);
        this.validationform.controls['WhatsappNo'].setValue(t.WhatsappNo);
        this.validationform.controls['Instagram'].setValue(t.Instagram);
        this.validationform.controls['Facebook'].setValue(t.Facebook);
        this.validationform.controls['Twitter'].setValue(t.Twitter);
        this.validationform.controls['Linkedin'].setValue(t.Linkedin);
        this.validationform.controls['Youtube'].setValue(t.Youtube);
      },
      (err: any) => {

      }
    );
  }

  onSubmit(): void {
    this.submit = true;
    var t = {
      MobileNo: this.validationform.controls['MobileNo'].value,
      Map: this.validationform.controls['Map'].value,
      Email: this.validationform.controls['Email'].value,
      Address: this.validationform.controls['Address'].value,
      WhatsappNo: this.validationform.controls['WhatsappNo'].value,
      Instagram: this.validationform.controls['Instagram'].value,
      Facebook: this.validationform.controls['Facebook'].value,
      Twitter: this.validationform.controls['Twitter'].value,
      Linkedin: this.validationform.controls['Linkedin'].value,
      Youtube: this.validationform.controls['Youtube'].value
    }
      this.userService.AddSupport(t).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
        },
        (err: any) => {

        }
      );
  
  }


}
