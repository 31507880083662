import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from '../../core/services/auth.service';
import { UserProfileService } from '../../core/services/user.service';
import { TokenStorageService } from '../../core/services/token-service.service';
import { ConfirmedValidator } from '../../core/services/confirmed.validator';
import { EventservService } from '../../core/services/eventserv.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {

  validationform: FormGroup;
  userflag: any;
  constructor(public formBuilder: FormBuilder,
    private userService: UserProfileService,
    private router: Router,
    private tokenStorage: TokenStorageService,
    private authService: AuthenticationService,
    private eventService: EventservService) { }

  breadCrumbItems: Array<{}>;

  submit: boolean;
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'SGTTA-SURAT' }, { label: 'Change Password', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.validationform = this.formBuilder.group({
      NewPassword: ['', [Validators.required]],
      ConfirmPassword: ['', Validators.required]
    }, {
      validator: ConfirmedValidator('NewPassword', 'ConfirmPassword')
    });

  }

  get form() {
    return this.validationform.controls;
  }

  edituserbyid(id: any) {
    //console.log(id);
    this.userService.finduserById(id).subscribe(
      (data: any) => {
        //console.log(this.userflag);
        var f = data;
        this.validationform.controls['Password'].setValue(f.Password);
      },
      (err: any) => {

      }
    );
  }

  onSubmit(): void {
    this.submit = true;
    if (this.userflag.roles == "Super Admin") {
      var t = { LoginId: this.userflag.id, Password: this.validationform.controls['NewPassword'].value }
      this.authService.changepassword(t).subscribe(
        (data: any) => {
          alert(data.message);
          this.authService.logout();
          this.router.navigate(['/account/login']);
        },
        (err: any) => {

        }
      );
    }
    // else if (this.userflag.roles == "Admin") {
    //   var t1 = { EventId: this.userflag.id, Password: this.validationform.controls['NewPassword'].value }
    //   this.eventService.changepassword(t1).subscribe(
    //     (data: any) => {
    //       alert(data.message);
    //       this.authService.logout();
    //       this.router.navigate(['/account/login']);
    //     },
    //     (err: any) => {

    //     }
    //   );
    // }
    else {
      var tt = { UserId: this.userflag.id, Password: this.validationform.controls['NewPassword'].value }
      this.userService.changepassworduser(tt).subscribe(
        (data: any) => {
          alert(data.message);
          this.authService.logout();
          this.router.navigate(['/account/login']);
        },
        (err: any) => {

        }
      );
    }

  }

}
