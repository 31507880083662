<div class="container-fluid">
    <app-pagetitle title="Category" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="tabindex" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-pen-square"></i></span>
                                <span class="d-none d-sm-block">Add Category</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="validationform">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Category Name</label>
                                                        <input type="text" class="form-control" id="validationCustom01" formControlName="CategoryName" placeholder="Category Name" [ngClass]="{'is-invalid': submit && form.CategoryName.errors}">
                                                        <div *ngIf="submit && form.CategoryName.errors" class="invalid-feedback">
                                                            <span *ngIf="form.CategoryName.errors.required">Please provide
                                                                a
                                                                Category Name</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom23">Event Image</label>
                                                        <input #myInput type="file" class="form-control" id="validationCustom23" formControlName="CatImage" [ngClass]="{'is-invalid': submit && form.CatImage.errors}" (change)="onFileChange($event)" accept="image/png,image/jpg, image/jpeg">
                                                        <div *ngIf="submit && form.CatImage.errors" class="invalid-feedback">
                                                            <span *ngIf="form.CatImage.errors.required">Please provide a
                                                                valid
                                                                Category Image.</span>
                                                        </div>
                                                        <img [src]="logoimgsrc" style="width:100px;" />
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">

                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" formControlName="IsActive">
                                                            <label class="form-check-label" for="defaultCheck3">
                                                              Is Active
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <button class="btn btn-primary" type="submit" [disabled]='validationform.invalid'>Save</button>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">Category List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="CategoryId" [showBorders]="true" (onExporting)="onExporting($event)">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <!-- <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column> -->
                                                <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="IsActive" caption="Is Active" [width]="100" dataType="string">
                                                </dxi-column>
                                                <dxi-column dataField="CatImg" caption="Category Image" [width]="150" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate">
                                                </dxi-column>
                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                                <div *dxTemplate="let data of 'cellTemplate'">
                                                    <img [src]="data.value" style="width:100px;" />
                                                </div>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav "></div>
                </div>
            </div>
        </div>
    </div>
</div>