<div class="container-fluid">
    <app-pagetitle title="Profile" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="tabindex" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-pen-square"></i></span>
                                <span class="d-none d-sm-block">Add Profile</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="validationform">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Name</label>
                                                        <input type="text" class="form-control" id="validationCustom01" formControlName="Name" placeholder="Name" [ngClass]="{'is-invalid': submit && form.Name.errors}">
                                                        <div *ngIf="submit && form.Name.errors" class="invalid-feedback">
                                                            <span *ngIf="form.Name.errors.required">Please provide
                                                                a
                                                                 Name</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Mobile No</label>
                                                        <input type="text" class="form-control" id="validationCustom01" formControlName="MobileNo" placeholder="Mobile No" [ngClass]="{'is-invalid': submit && form.MobileNo.errors}">
                                                        <div *ngIf="submit && form.MobileNo.errors" class="invalid-feedback">
                                                            <span *ngIf="form.MobileNo.errors.required">Please provide
                                                                a
                                                                Mobile No</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Email</label>
                                                        <input type="text" class="form-control" id="validationCustom01" formControlName="Email" placeholder="Email" [ngClass]="{'is-invalid': submit && form.Email.errors}">
                                                        <div *ngIf="submit && form.Email.errors" class="invalid-feedback">
                                                            <span *ngIf="form.Email.errors.required">Please provide
                                                                a
                                                                Email</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">

                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="validationCustom23">Product Image</label>
                                                        <input #myImg type="file" class="form-control" id="validationCustom23" formControlName="ProfileImage" [ngClass]="{'is-invalid': submit && form.ProfileImage.errors}" (change)="onFileChange($event)" accept="image/png,image/jpg, image/jpeg">
                                                        <div *ngIf="submit && form.ProfileImage.errors" class="invalid-feedback">
                                                            <span *ngIf="form.ProfileImage.errors.required">Please provide a
                                                                valid
                                                                Profile Image.</span>
                                                        </div>
                                                        <img id="img" [src]="logoimgsrc" style="width:100px;" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">

                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" formControlName="IsActive">
                                                            <label class="form-check-label" for="defaultCheck1">
                                                              Is Active
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <button class="btn btn-primary" type="submit" [disabled]='validationform.invalid'>Save</button>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">Profile List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label for="validationCustom23">Upload Bulk Profile</label>
                                                <input #myInput type="file" class="form-control" id="validationCustom23" (change)="onFileUpChange($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="table-responsive">
                                                <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="UserId" [showBorders]="true">
                                                    <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                    <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                    <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                    <dxi-column type="buttons" [width]="50">
                                                        <dxi-button template="myCommand">
                                                            <div *dxTemplate="let data of 'myCommand'">
                                                                <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                            </div>
                                                        </dxi-button>
                                                    </dxi-column>
                                                    <!-- <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column> -->
                                                    <dxi-column dataField="MobileNo" caption="Mobile No" [width]="200" dataType="string">

                                                    </dxi-column>
                                                    <dxi-column dataField="Name" caption="Name" [width]="300" dataType="string">

                                                    </dxi-column>
                                                    <dxi-column dataField="Email" caption="E-mail" [width]="300" dataType="string">

                                                    </dxi-column>
                                                    <dxi-column dataField="IsActive" caption="Is Active" [width]="100" dataType="string">

                                                    </dxi-column>
                                                    <dxi-column dataField="ProfileImg" caption="Profile Image" [width]="150" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate">
                                                    </dxi-column>
                                                    <dxo-pager>
                                                        [showNavigationButtons]="true" [showInfo]= "true"
                                                    </dxo-pager>
                                                    <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                                    <div *dxTemplate="let data of 'cellTemplate'">
                                                        <img [src]="data.value" style="width:100px;" />
                                                    </div>
                                                </dx-data-grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
    </div>
</div>