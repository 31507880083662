import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CategoryService } from '../../core/services/category.service';
import { SubcategoryService } from '../../core/services/subcategory.service';
import { TokenStorageService } from '../../core/services/token-service.service';
import { EventservService } from '../../core/services/eventserv.service';
import { DataTableDirective } from 'angular-datatables';
import * as XLSX from 'xlsx';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.scss']
})
export class SubcategoryComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  datalist = [];
  currentFilter: any;
  applyFilterTypes: any;

  validationform: FormGroup;
  searchform: FormGroup;
  tabindex = 1;
  flag: number = 0;
  addflag: any = '';
  viewflag: any = 'active';
  userflag: any;
 
  catlist = [];
  eventlist = [];
  seventlist = [];
  temp = [];
  searchlist = [];
  scatlist = [];
  logoimg: any;
  logoimgsrc: any;
  columns = [
    { prop: 'SubcategoryName', name: 'Sub-Category Name' },
    { prop: 'CategoryName', name: 'Category Name' },
    { prop: 'EventName', name: 'Event Name' },
    { prop: 'UserType', name: 'User Type' },
    { prop: 'IsActive', name: 'Is Active' }
  ];
  //ngbNav: NgbNav;
  constructor(public formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService,
    private eventService: EventservService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private tokenStorage: TokenStorageService) {
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];
    this.currentFilter = this.applyFilterTypes[0].key;
  }

  // bread crumb items
  breadCrumbItems: Array<{}>;
  fileName = 'ProductList.xlsx';
  submit: boolean;
  dtOptions: any = {};
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'SGTTA-SURAT' }, { label: 'Product', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.validationform = this.formBuilder.group({
      SubcategoryName: ['', [Validators.required]],
      //CategoryId: ['', [Validators.required]],
      SubcatImage: '',
      IsActive: [true],
      CreatedBy: [this.userflag.id],
      SubcategoryId: 0,
      DisplayOrder: [0,[Validators.required]]
    });

    this.searchform = this.formBuilder.group({
      CategoryId: [0]
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      processing: true
    };

    this.submit = false;
    this.getdata();
    //this.getcategory();
  }

  ngAfterViewInit(): void {
    // var datatable = $('#tbl').DataTable();
    // datatable.draw();

  }

  ngOnDestroy(): void {
    // var datatable = $('#tbl').DataTable();
    // console.log(datatable);
    // datatable.destroy();
    // datatable.draw();
  }

  get form() {
    return this.validationform.controls;
  }

  get f() {
    return this.searchform.controls;
  }

  cleartext() {
    this.validationform.setValue({
      SubcategoryName: ['', [Validators.required]],
      //CategoryId: ['', [Validators.required]],
      SubcatImage: '',
      IsActive: [true],
      CreatedBy: [this.userflag.id],
      SubcategoryId: 0,
      DisplayOrder: [0,[Validators.required]]
    });
    this.flag = 0;
    this.submit = false;
  }

  getdata() {
    // var datatable = $('#tbl').DataTable();
    // console.log(datatable);
    //datatable.destroy();
    this.subcategoryService.getsubcategory().subscribe(
      (data: any) => {
        this.datalist = data;
        this.temp = data;
        //datatable.draw();
      },
      (err: any) => {

      }
    );


  }

  getcategory() {

    this.categoryService.getcategory().subscribe(
      (data: any) => {
        this.catlist = data;
      },
      (err: any) => {

      }
    );

  }


  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.logoimg = this.generate() + '_' + file.name;
      reader.readAsDataURL(file);
      var img = new Image();
      reader.onload = () => {
        img.src = reader.result as string;
      };

      img.onload = () => {
        this.logoimgsrc = this.imageToDataUri(img, 500, 500);
      }

    }
  }

  imageToDataUri(img, width, height) {

    // create an off-screen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
  }

  generate() {
    const characters = 'abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result +=
        characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  onSubmit(): void {
    this.submit = true;
    if (this.flag == 0) {

      this.subcategoryService.addsubcategory(this.validationform.value, this.logoimg, this.logoimgsrc).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.logoimgsrc = "";
          
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
    else {
      this.subcategoryService.updatesubcategory(this.validationform.value, this.logoimg, this.logoimgsrc).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.logoimgsrc = "";
         
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
  }


  edituserbyid(e) {
    //console.log(id);
    this.subcategoryService.findsubcategoryById(e.row.data.SubcategoryId).subscribe(
      (data: any) => {
        //console.log(this.userflag);
        var f = data;
        this.validationform.setValue({
          SubcategoryName: f.SubcategoryName,
          //CategoryId: f.CategoryId,
          SubcatImage: '',
          IsActive: f.IsActive == 'Yes' ? true : false,
          CreatedBy: this.userflag.id,
          SubcategoryId: f.SubcategoryId,
          DisplayOrder: f.DisplayOrder
        });
        this.logoimg = f.SubcatImage;
        this.logoimgsrc = f.SubcatImg == '' ? '' : this.sanitizer.bypassSecurityTrustUrl('http://api.sgtta.org/uploads/subcategory/' + f.SubcatImage);
        this.flag = 1;
        this.tabindex = 1;
        //this.nav.select(1);
      },
      (err: any) => {

      }
    );
  }

  deleteuser(e) {
    this.subcategoryService.deletesubcategory(e.row.data.SubcategoryId).subscribe(
      (data: any) => {
        alert(data.message);
        this.getdata();
      },
      (err: any) => {

      }
    );
  }

  getcategorysearch(value) {
    if (this.searchform.controls['UserType'].value != undefined && this.searchform.controls['UserType'].value != '' && this.searchform.controls['UserType'].value != null) {
      this.categoryService.GetCatbyEventId(value, this.searchform.controls['UserType'].value).subscribe(
        (data: any) => {
          this.scatlist = data;
        },
        (err: any) => {

        }
      );
    }
    else {
      console.log(value);
      this.categoryService.GetCatbyEventId(value, 'User').subscribe(
        (data: any) => {
          this.scatlist = data;
        },
        (err: any) => {

        }
      );
    }
  }

  onchangesearch(type) {
    if (this.searchform.controls['EventId'].value != undefined && this.searchform.controls['EventId'].value != '' && this.searchform.controls['EventId'].value != null) {
      this.categoryService.GetCatbyEventId(this.searchform.controls['EventId'].value, type).subscribe(
        (data: any) => {
          this.scatlist = data;
        },
        (err: any) => {

        }
      );
    }
    else {
      this.scatlist = [];
    }
  }

  searchterm() {


    if (this.searchform.controls['EventId'].value != '' && this.searchform.controls['UserType'].value != '' && this.searchform.controls['CategoryId'].value == 0) {
      // var datatable = $('#tbl').DataTable();
      // console.log(datatable);
      //datatable.destroy();
      this.datalist = this.temp.filter(i => i.EventId == this.searchform.controls['EventId'].value && i.UserType == this.searchform.controls['UserType'].value);
      console.log(this.datalist);
      // datatable.draw();
    }
    else if (this.searchform.controls['EventId'].value != '' && this.searchform.controls['UserType'].value != '' && this.searchform.controls['CategoryId'].value != '') {
      // var datatable = $('#tbl').DataTable();
      // console.log(datatable);
      //datatable.destroy();
      this.datalist = this.temp.filter(i => i.EventId == this.searchform.controls['EventId'].value && i.UserType == this.searchform.controls['UserType'].value && i.CategoryId == this.searchform.controls['CategoryId'].value);
      console.log(this.datalist);
      // datatable.draw();
    }
  }

  exporttoexcel(): void {
    if (this.searchform.controls['EventId'].value != '' && this.searchform.controls['UserType'].value != '' && this.searchform.controls['CategoryId'].value == '') {

      this.datalist = this.temp.filter(i => i.EventId == this.searchform.controls['EventId'].value && i.UserType == this.searchform.controls['UserType'].value);
      this.searchlist = this.temp.filter(i => i.EventId == this.searchform.controls['EventId'].value && i.UserType == this.searchform.controls['UserType'].value);
      let lst = [];

      for (let i = 0; i < this.searchlist.length; i++) {
        lst.push({ SubcategoryName: this.searchlist[i].SubcategoryName, CategoryName: this.searchlist[i].CategoryName, EventName: this.searchlist[i].EventName, UserType: this.datalist[i].UserType, IsActive: this.searchlist[i].IsActive });
      }

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lst);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }
    else if (this.searchform.controls['EventId'].value != '' && this.searchform.controls['UserType'].value != '' && this.searchform.controls['CategoryId'].value != '') {

      this.datalist = this.temp.filter(i => i.EventId == this.searchform.controls['EventId'].value && i.UserType == this.searchform.controls['UserType'].value && i.CategoryId == this.searchform.controls['CategoryId'].value);
      this.searchlist = this.temp.filter(i => i.EventId == this.searchform.controls['EventId'].value && i.UserType == this.searchform.controls['UserType'].value && i.CategoryId == this.searchform.controls['CategoryId'].value);
      let lst = [];

      for (let i = 0; i < this.searchlist.length; i++) {
        lst.push({ SubcategoryName: this.searchlist[i].SubcategoryName, CategoryName: this.searchlist[i].CategoryName, EventName: this.searchlist[i].EventName, UserType: this.datalist[i].UserType, IsActive: this.searchlist[i].IsActive });
      }

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lst);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }
  }

  reset(): void {
    this.searchform.reset();
    this.getdata();
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ProductList.xlsx');
      });
    });
    e.cancel = true;
  }

}
